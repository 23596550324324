/// <reference path='../_reference.ts' />

module Apex.Crm.Controllers {

    export interface CompanyScope extends angular.IScope {
        splitPaneProperties: any;
        maps: {
            showMap: boolean,
            markers?: any
        };
        Components: {
            companyGrid: Apex.Components.IApxGrid,
            companyGrid1: Apex.Components.IApxGrid,
            branchTreeList: Apex.Components.IApxTreeList
        };
        filter: {
            isOwn?: boolean,
            isPartner?: boolean,
            isSupplier?: boolean,
            isCustomer?: boolean
        },
        events: {
            refresh: Function,
            add: Function,
            edit: Function,
            delete: Function,
            mouseClick?: Function
            branchEvents: {
                refresh?: Function,
                expand?: Function,
                collapse?: Function,
                add?: Function,
                addIn?: Function,
                edit?: Function,
                delete?: Function
            }
        },
        settings?: {
            editable?: boolean,
            company?: {
                selectedCompany?: Apex.Crm.Interface.ICompany,
                selectedCompanyForMap?: Apex.Crm.Interface.ICompany
            },
            branch?: {
                selectedBranchId?: number;
                selectedBranch?: any;
            }
        };
        disabledButton: boolean
        tabControls: {
            selectedTabPageIndex?: number;
            tabEvents: {
                tabChange: Function;
            }
        };
        treeListHeight: number;
        getOnlyTymeValue: Function;
        style?: any;
        model?: {
            branch?: {
                dataSource?: kendo.data.TreeListDataSource,
                disableAll: boolean,
                disableBtns: boolean
            }
        }

    }

    export class CompanyController extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.translate, 'crmService', 'commonService', "$timeout", 'leafletData', '$log'];
        constructor(private $scope: CompanyScope, private toaster: any, private $translate, private crmService: Apex.Crm.Services.Crm,
            private commonService: Apex.Operations.Services.Common, public $timeout, private leafletData, $log) {
            super(arguments, CompanyController.$inject);
            this.initialisation();

            $scope.$watch("Components.companyGrid.grid", (scop) => {
                $("#context-menu").kendoContextMenu({
                    target: "#compPanel",
                    open: (e) => {
                        if ($scope.disabledButton === true) {
                            $("#edit").css("opacity", "0.4");
                            $("#delete").css("opacity", "0.4");
                        }
                        else {
                            $("#edit").css("opacity", "1");
                            $("#delete").css("opacity", "1");
                        }
                    }
                });

                $("#context-menu1").kendoContextMenu({
                    filter: "._branch-tree-list",
                    open: (e) => {
                        var data = (this.$scope.Components.branchTreeList.treeList.select()).data();
                        var dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();

                        if (data) {
                            this.$scope.model.branch.disableBtns = false;
                            $("#refresh-popup").css("opacity", "1");
                            $("#p-add-popup").css("opacity", "1");
                            $("#add-popup").css("opacity", "1");
                            $("#add-under-popup").css("opacity", "1");
                            $("#edit-popup").css("opacity", "1");
                            $("#delete-popup").css("opacity", "1");
                            $("#expand-popup").css("opacity", "1");
                            $("#collapse-popup").css("opacity", "1");
                        } else {
                            this.$scope.model.branch.disableBtns = true;
                            if (!dataSource || dataSource.length == 0) {
                                $("#expand-popup").css("opacity", "0.4");
                                $("#collapse-popup").css("opacity", "0.4");
                            }
                            $("#refresh-popup").css("opacity", "0.4");
                            $("#p-add-popup").css("opacity", "0.4");
                            $("#edit-popup").css("opacity", "0.4");
                            $("#delete-popup").css("opacity", "0.4");

                        }
                    }
                });
            });

            // $timeout(() => {
            //     $("#pp").find(".split-handler").css("left", "60%");
            // });

            $(window).resize(() => {
                var bodySize = $("#pageBody").innerHeight() - $("#first-ui-tabset").innerHeight();
                $("#bg-splitter-id").innerHeight(bodySize - 20);
            });


        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.disabledButton = true;
            this.$scope.settings.company.selectedCompany = null;
            this.refreshButtonClick();
        }

        initialisation = () => {
            this.$scope.model = {
                branch: {
                    disableAll: true,
                    disableBtns: true
                }
            }
            this.$scope.filter = {
                isOwn: false,
                isPartner: false,
                isSupplier: false,
                isCustomer: false
            };

            this.$scope.splitPaneProperties = {};
            this.$scope.maps = {
                showMap: false,
                markers: []
            };

            this.$scope.getOnlyTymeValue = this.getOnlyTime;
            this.$scope.settings = {
                editable: false,
                company: {},
                branch: {
                }
            }


            this.$scope.tabControls = {
                selectedTabPageIndex: 0,
                tabEvents: {
                    tabChange: this.tabSelectChange
                }
            };

            this.$scope.Components = {
                companyGrid: this.initCompanyGrid(),
                companyGrid1: this.initCompanyGrid1(),
                branchTreeList: this.initBranchTreeList()
            }
            this.$scope.disabledButton = true;

            this.$scope.events = {
                refresh: this.refreshButtonClick,
                add: this.addCompany,
                edit: this.editCompany,
                delete: this.deleteButtonClick,
                branchEvents: {
                    refresh: this.refreshBranch,
                    add: this.addBranch,
                    addIn: this.addInBranch,
                    edit: this.editBranch,
                    delete: this.deleteBranch,
                    expand: this.expandBranch,
                    collapse: this.collapseBranch
                }
            }
        }


        addCompany = () => {
            this.openModal(null);
        }
        editCompany = () => {
            if (this.$scope.disabledButton == false)
                this.openModal(this.$scope.settings.company.selectedCompany);
        }
        openModal = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm('company.modal.html', CompanyModal.getName(), data, Core.Utils.ModalSize.Large);
            dlg.result.then(result => {
                if (result == true) {
                    this.refreshButtonClick();
                }
            });
        }


        openEditBranchModal = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm('companyBranch.modal.html', BranchModal.getName(), data, Core.Utils.ModalSize.Medium);
            dlg.result.then(result => {
                if (typeof result !== "undefined" && result != null && result != false) {
                    result.CompanyId = this.$scope.settings.company.selectedCompany.Id;
                    var _dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();
                    this.crmService.saveBranchesAsync(new Array<Apex.Crm.Interface.ICompanyBranch>(result)).then(s_result => {
                        if (s_result.Result != undefined && s_result.Result > 0) {
                            result.Id = s_result.Result;
                            if (_dataSource.length > 0) {
                                for (var item in _dataSource) {
                                    if (Number(item) !== Number(item))
                                        break;
                                    if (_dataSource[item].Id === result.Id) {
                                        _dataSource[item] = result;
                                        this.$scope.Components.branchTreeList.treeList.dataSource.sync();
                                        return;
                                    }
                                }
                            }
                            result.parentId = result.ParentId;
                            _dataSource.push(result);
                            this.$scope.Components.branchTreeList.treeList.dataSource.sync();
                        }
                        this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successSaved'));
                    });
                }
            });
        }

        ///#region  button events
        refreshBranch = () => {
           
            
            if (this.$scope.settings.company.selectedCompany != undefined) {
                this.$scope.Components.branchTreeList.showLoading();
                this.crmService.getCompanyBranch(null, this.$scope.settings.company.selectedCompany.Id).then(result => {
                    for (var index = 0; index < result.Result.length; index++) {
                        if(!result.Result[index].ParentId){
                            result.Result[index].ParentId=null;
                            break;
                        }
                    }
                    this.$scope.Components.branchTreeList.treeList.setDataSource(this.convertToTreeListDataSource(result.Result));
                    this.$scope.Components.branchTreeList.hideLoading();
                });
            }
        }

        addBranch = () => {
            if (this.$scope.model.branch.disableBtns) return;

            if (this.$scope.settings.branch.selectedBranch != null && this.$scope.settings.branch.selectedBranch != undefined) {
                this.openEditBranchModal({
                    parentId: this.$scope.settings.branch.selectedBranch.parentId == null ? this.$scope.settings.branch.selectedBranchId : this.$scope.settings.branch.selectedBranch.parentId,
                    branch: null
                });
            }
        }

        addInBranch = () => {
            if (this.$scope.model.branch.disableBtns) return;

            if (this.$scope.settings.branch.selectedBranch != null && this.$scope.settings.branch.selectedBranch != undefined) {
                this.openEditBranchModal({
                    parentId: this.$scope.settings.branch.selectedBranchId,
                    branch: null
                });
            }
        }
        editBranch = () => {
            if (this.$scope.model.branch.disableBtns) return;

            if (this.$scope.settings.branch.selectedBranchId != undefined && this.$scope.settings.branch.selectedBranchId)
                this.openEditBranchModal({
                    parentId: null,
                    branch: this.$scope.settings.branch.selectedBranch
                });
        }

        deleteBranch = () => {
            if (this.$scope.model.branch.disableBtns) return;

            var selected_branch = this.$scope.Components.branchTreeList.treeList.select();
            Core.Utils.ModalForm.openDeleteConfirmations(() => {
                // this.$scope.Components.branchTreeList.treeList.removeRow(selected_branch);

                if (this.$scope.settings.branch.selectedBranch != undefined && this.$scope.settings.branch.selectedBranch != null) {
                    this.crmService.deleteBranch(this.$scope.settings.branch.selectedBranch.Id).then(result => {
                        this.$scope.Components.branchTreeList.treeList.removeRow(selected_branch);
                        this.$scope.settings.branch.selectedBranch = null;
                        this.$scope.settings.branch.selectedBranchId = null;
                        this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successDeleted'));
                    });
                }
            });
        }

        expandBranch = () => {
            var rows = this.$scope.Components.branchTreeList.treeList.element.find("tr.k-treelist-group");
            if (rows && rows.length > 0) {
                for (var i = 0; i < rows.length; i++) {
                    this.$scope.Components.branchTreeList.treeList.expand(rows[i]);
                }
            }
        }
        collapseBranch = () => {
            var elem_id = this.$scope.Components.branchTreeList.treeList.element.attr("id");
            var elem=$("#" + elem_id + " tbody>tr:eq(0)");
            if(elem.length>0)
                (<any>$("#" + elem_id).data("kendoTreeList")).collapse(elem);
        }

        deleteButtonClick = () => {
            if (this.$scope.disabledButton == false) {
                var selectedCompany = this.$scope.Components.companyGrid.gridView.selectedItem();
                if (!!selectedCompany) {
                    Core.Utils.ModalForm.openDeleteConfirmations(() => {
                        this.crmService.deleteCompany(selectedCompany.Id).then((result: any) => {
                            if (!!result.statusCode) {
                                this.toaster.pop('error', "Error", this.$translate.instant('dontDelete'));
                            } else {
                                this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('successDeleted'));
                                this.$scope.Components.companyGrid.dataSource.remove(selectedCompany);
                            }
                        });
                    });
                }
            }
        }
        refreshButtonClick = () => {
            if (this.$scope.tabControls.selectedTabPageIndex == 0)
                this.$scope.Components.companyGrid.gridView.showLoading();
            else this.$scope.Components.companyGrid1.gridView.showLoading();

            this.crmService.getCompanies(this.$scope.filter.isOwn, this.$scope.filter.isPartner, this.$scope.filter.isSupplier, this.$scope.filter.isCustomer).then(result => {
                var dataSource = result.Result;
                this.$scope.Components.companyGrid.dataSource.data(dataSource);

                dataSource.splice(0, 0, { Code: "", Name: "ყველა" });
                this.$scope.Components.companyGrid1.dataSource.data(dataSource);

                if (this.$scope.tabControls.selectedTabPageIndex == 0)
                    this.$scope.Components.companyGrid.gridView.hideLoading();
                else this.$scope.Components.companyGrid1.gridView.hideLoading();

                this.$scope.Components.branchTreeList.treeList.setDataSource(this.convertToTreeListDataSource([]));
                this.$scope.settings.company.selectedCompany = null;
            });

        }
        ///#endregion


        ///#region initialize grid and its dataSource
        initBranchAccountParamGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initBranchAccountParamDataSource(),
                columns: this.branchAccountGridColumns,
                settings: { editble: { disableEditRow: true } }
            }
            return grid;
        }
        initBankAccountDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        fields: {
                            Account: { type: "string" },
                            Note: { type: "string" },
                            BankName: { type: "string" }
                        }
                    }
                }
            });
        }
        initBranchAccountParamDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        fields: {
                            Account: { type: "string" },
                            AccountTypeName: { type: "string" }
                        }
                    }
                }
            });
        }

        initCompanyGrid1 = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.CompanyGrid1Columns,
                    settings: { editble: { disableEditRow: true }, pageable: true, selectable: "multiple" },
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    if (this.$scope.settings.company.selectedCompanyForMap === this.$scope.Components.companyGrid1.gridView.selectedItem())
                                        return;
                                    this.$scope.settings.company.selectedCompanyForMap = this.$scope.Components.companyGrid1.gridView.selectedItem();
                                    this.$scope.maps.markers = [];
                                    if (this.$scope.settings.company.selectedCompanyForMap !== null && !!this.$scope.settings.company.selectedCompanyForMap) {
                                        kendo.ui.progress($("#map"), true);
                                        if (this.$scope.settings.company.selectedCompanyForMap.Id) {
                                            this.crmService.getCompanyBranches(this.$scope.settings.company.selectedCompanyForMap.Id, null, null, null, null).then(result => {
                                                this.makeMarkersFromBranches(result.Result);
                                                kendo.ui.progress($("#map"), false);
                                            });
                                        } else {
                                            this.crmService.getCompanyBranches(null, this.$scope.filter.isOwn, this.$scope.filter.isPartner, this.$scope.filter.isSupplier, this.$scope.filter.isCustomer).then(result => {
                                                this.makeMarkersFromBranches(result.Result);
                                                kendo.ui.progress($("#map"), false);
                                            });
                                        }

                                    }

                                });
                            }
                        }
                    ]

                };
            return grid;
        }

        initCompanyGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.CompanyGridColumns,
                    settings: { editble: { disableEditRow: true }, pageable: true },
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    if (this.$scope.settings.company.selectedCompany === this.$scope.Components.companyGrid.gridView.selectedItem())
                                        return;
                                    this.$scope.settings.company.selectedCompany = this.$scope.Components.companyGrid.gridView.selectedItem();

                                    if (this.$scope.settings.company.selectedCompany !== null && !!this.$scope.settings.company.selectedCompany) {
                                        this.$scope.disabledButton = false;
                                        this.refreshBranch();
                                    } else {
                                        this.$scope.disabledButton = true;
                                        this.$scope.Components.branchTreeList.treeList.setDataSource(this.convertToTreeListDataSource([]));
                                        this.$scope.settings.branch.selectedBranch = null;
                                        this.$scope.settings.branch.selectedBranchId = null;
                                    }

                                });
                            }
                        }
                    ]

                };

            return grid;
        }

        getGridDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                pageSize: 100,
                schema: {
                    model: {
                        fields: {
                            Code: { type: "string" },
                            Name: { type: "string" },
                            Phone: { type: "string" },
                            Email: { type: "string" },
                            'Address.FullAddress': { type: "string" },
                            NameEn: { type: "string" }
                        }
                    }
                }
            });
        }
        ///#endregion 


        ///#region initialise treeList and its dataSource
        initBranchTreeList = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    columns: this.BranchTreeListColumns,
                    dataSource: this.convertToTreeListDataSource([]),
                    change: this.branchTreeListSelectedChange,
                    editable: {
                        mode: "inline"
                    },
                    filterable: true,
                    resizable: true,
                    sortable: { mode: "single" },
                    selectable: true
                }
            }
            return _treeList;
        }

        branchTreeListSelectedChange = (args: any) => {
            Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                this.$scope.settings.branch.selectedBranch = (<JQuery>args.sender.select()).data().$scope.dataItem;
                if (this.$scope.settings.branch.selectedBranchId === (<JQuery>args.sender.select()).data().$scope.dataItem.Id)
                    return;
                this.$scope.settings.branch.selectedBranchId = (<JQuery>args.sender.select()).data().$scope.dataItem.Id;

            });

        }
        ///#endregion 

        tabSelectChange = (index: any) => {
            this.$scope.tabControls.selectedTabPageIndex = index;
            if (index === 1) {
                this.$scope.disabledButton = true;
                if (!this.$scope.maps.showMap) {
                    this.$scope.maps.markers = [];
                    this.$timeout(() => {
                        Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.$scope.maps.showMap = true;
                        });
                    });
                }
            } else if (!!this.$scope.settings.company.selectedCompany)
                this.$scope.disabledButton = false;
        }

        initBankAccountGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initBankAccountDataSource(),
                columns: this.bankAccountGridColumns,
                settings: { editble: { disableEditRow: true } }
            }
            return grid;
        }

        getOnlyTime = (date: Date) => {
            if (!!date && date !== undefined)
                return date.getHours() + ':' + date.getMinutes();
            else
                return null;
        }
        convertToTreeListDataSource = (data: any): kendo.data.TreeListDataSource => {

            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded:true,
                        id: "Id",
                        fields: {
                            parentId: { field: "ParentId", nullable: true }
                        }
                    }
                }
            });
        }
        convertToValidDate = (serviceStackDate: any) => {
            if (!serviceStackDate)
                return;

            if (serviceStackDate.ScheduleDays !== undefined) {
                for (var item in serviceStackDate.ScheduleDays) {
                    if (!!serviceStackDate.ScheduleDays[item]) {
                        if (serviceStackDate.ScheduleDays[item].From) {
                            serviceStackDate.ScheduleDays[item].hasScheduler = serviceStackDate.ScheduleDays[item].From !== "undefined";
                            serviceStackDate.ScheduleDays[item].From = new Date(parseInt(serviceStackDate.ScheduleDays[item].From.substr(6)));
                            serviceStackDate.ScheduleDays[item].stringFrom = serviceStackDate.ScheduleDays[item].From.getHours() + ':' + serviceStackDate.ScheduleDays[item].From.getMinutes();
                        }
                        if (serviceStackDate.ScheduleDays[item].To) {
                            serviceStackDate.ScheduleDays[item].To = new Date(parseInt(serviceStackDate.ScheduleDays[item].To.substr(6)));
                            serviceStackDate.ScheduleDays[item].stringTo = serviceStackDate.ScheduleDays[item].To.getHours() + ':' + serviceStackDate.ScheduleDays[item].To.getMinutes();
                        }
                        if (serviceStackDate.ScheduleDays[item].SFrom) {
                            serviceStackDate.ScheduleDays[item].hasSScheduler = serviceStackDate.ScheduleDays[item].SFrom !== "undefined";
                            serviceStackDate.ScheduleDays[item].SFrom = new Date(parseInt(serviceStackDate.ScheduleDays[item].SFrom.substr(6)));
                            serviceStackDate.ScheduleDays[item].stringSFrom = serviceStackDate.ScheduleDays[item].SFrom.getHours() + ':' + serviceStackDate.ScheduleDays[item].SFrom.getMinutes();
                        }
                        if (serviceStackDate.ScheduleDays[item].STo) {
                            serviceStackDate.ScheduleDays[item].STo = new Date(parseInt(serviceStackDate.ScheduleDays[item].STo.substr(6)));
                            serviceStackDate.ScheduleDays[item].stringSTo = serviceStackDate.ScheduleDays[item].STo.getHours() + ':' + serviceStackDate.ScheduleDays[item].STo.getMinutes();
                        }
                    }
                }
            }
        }


        makeMarkersFromBranches = (branches: Array<Apex.Crm.Interface.ICompanyBranch>) => {
            if (branches !== null || branches !== undefined) {

                this.$scope.maps.markers = [];
                _.forEach(branches, (item) => {
                    var t = <Apex.Crm.Interface.ICompanyBranch>item;
                    if (t.Address !== undefined && t.Address.Location != undefined && t.Address.Location != null && t.Address.Location != 'undefined') {
                        if (t.Address.FullAddress == undefined)
                            t.Address.FullAddress = "";
                        this.$scope.maps.markers.push({
                            layer: "cluster",
                            lat: t.Address.Location.Latitude,
                            lng: t.Address.Location.Longitude,
                            message: "<h4 style='color:black;'>" + t.Name + "</h4>" + "<p>" + t.Address.FullAddress + "</p>"
                        });
                    }

                });
            }
        }

        ///#region Columns

        BranchTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Id", hidden: true },
            { field: "Code", width: 150, titleTranslated: "code" },
            { field: "Name", width: 200, titleTranslated: "name" },
            { field: "Address.FullAddress", width: 200, titleTranslated: "address" }
        ];

        bankAccountGridColumns: Array<Apex.Core.Interfaces.IGridColumn> = [
            { field: "Account", titleTranslated: "acc" },
            { field: "Note", titleTranslated: "note" },
            { field: "BankName", titleTranslated: "bank" }
        ]

        branchAccountGridColumns: Array<Apex.Core.Interfaces.IGridColumn> = [
            { field: "Account", titleTranslated: "acc" },
            { field: "AccountTypeName", titleTranslated: "acc_type" }
        ]

        CompanyGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            { field: "Code", titleTranslated: "snFull", width: 80 },
            { field: "Name", titleTranslated: "caption", width: 200 },
            { field: "Phone", titleTranslated: "phone", width: 80 },
            { field: "Email", titleTranslated: "email", width: 100 },
            { field: "Address.FullAddress", titleTranslated: "address", template: '<span >#: !!Address.FullAddress?Address.FullAddress:"" #</span>', width: 200 },
            { field: "NameEn", titleTranslated: "nameCaptionEn", width: 100 }
        ];

        CompanyGrid1Columns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            { field: "Code", titleTranslated: "snFull", width: 80 },
            { field: "Name", titleTranslated: "caption", width: 200 }
        ];
        ///#endregion
    }
}