/// <reference path='../../../Apex.WebUI.Core/app/Interfaces/ICoreInterface.ts' />
module Apex.Crm.Interface {
    "use strict";

    // @DataContract
    export class GetBranchPersonsResponse {
        // @DataMember(Order=1)
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;

        // @DataMember(Order=2)
        Result: BranchPerson[];
    }
    // @DataContract
    export class BranchPerson {
        // @DataMember(Order=1)
        Id: number;

        // @DataMember(Order=2)
        BranchId: number;

        // @DataMember(Order=3)
        Person: CrmPersons;
    }

    // @DataContract
    export class CrmPersons {
        // @DataMember(Order=1)
        Id: number;

        // @DataMember(Order=2)
        PersonSN: string;

        // @DataMember(Order=3)
        PName: string;

        // @DataMember(Order=4)
        FirstName: string;

        // @DataMember(Order=5)
        LastName: string;

        // @DataMember(Order=6)
        SecondName: string;

        // @DataMember(Order=7)
        DateBorn: string;

        // @DataMember(Order=8)
        Phone: string;

        // @DataMember(Order=9)
        Mobile: string;

        // @DataMember(Order=10)
        WorkPhone: string;

        // @DataMember(Order=11)
        Email: string;

        // @DataMember(Order=12)
        CityId: string;

        // @DataMember(Order=13)
        Description: string;

        // @DataMember(Order=14)
        Note: string;

        // @DataMember(Order=15)
        Citizenship: number;

        // @DataMember(Order=16)
        Is_Rezident: boolean;

        // @DataMember(Order=17)
        Sex: number;

        // @DataMember(Order=18)
        Spend: boolean;

        // @DataMember(Order=19)
        Unid: string;

        // @DataMember(Order=20)
        Phones: IPhones[];

        // @DataMember(Order=21)
        EMails: IEmail[];

        // @DataMember(Order=22)
        WebPages: IWebPage[];

        // @DataMember(Order=23)
        Position: string;
    }
    export class GetCrmPrOrderStatusResponse {
        ResponseStatus:Apex.Core.Interfaces.ResponseStatus;
        Result:PrOrderStatuses[];
    }

    export class PrOrderStatuses {
        Id: number;
        Name: string;
        Rezerv: boolean;
    }

    export interface IBranchPerson {
        BranchId?: number;
        Id?: number;
        Person?: IPerson;
    }

    export interface IBank {
        BankBic?: string;
        BankCode?: string;
        BankId?: string;
        BankMfo?: string;
        BankNe?: string;
        BankNu?: string;
        BankSa?: string;
        CityId?: string;
        LDefault?: boolean;
        Spend?: boolean;
    }
    export interface IGPSLocation {
        Latitude?: number;
        Longitude?: number;
    }

    export interface IScheduleDay {
        Day?: number;
        hasScheduler?: boolean;
        hasSScheduler?: boolean;
        From?: Date;
        To?: Date;
        SFrom?: Date;
        STo?: Date;
    }

    export interface IBranchSchedule {
        BranchId?: number;
        ScheduleDays: IScheduleDay[];
    }

    export interface ICustomerParams {
        BranchId?: number;
        CreditDays?: number;
        CreditLimit?: number;
        CustomerCategory?: number;
        CustomerType?: number;
        OverdueLimit?: number;
        ProductType?: number;
    }

    export interface ISupplierParams {
        BranchId?: number;
        ProdSupplyDays?: number;
        MinOrderAmmount?: number;
        OrderGenIntervalDays?: number;
        Importer?: boolean;
        MinOrderQuantity?: number;
        AutoOrder?: boolean;
    }


    export interface ICompany {
        Address: IAddress;
        AddressId: number;
        Code: string;
        Email: string;
        Fax: string;
        Id: number;
        IsCustomer: boolean;
        IsOwn: boolean;
        IsPartner: boolean;
        IsSupplier: boolean;
        Name: string;
        NameEn: string;
        Note: string;
        Phone: string;
        Spend: string;
        VatType: number;
        Web: string;
    }
    export interface ICompanyBankAccount {
        Id?: number;
        BranchId?: number;
        BankId: string;
        Account: string;
        Note: string;
        IsDefault: boolean;
    }

    export interface ICompanyBankAccountView {

        Id?: number;
        BranchId?: number;
        BankId: string;
        Account: string;
        Note: string;
        IsDefault: boolean;
        BankName: string;
    }
    export interface ICompanyBranch {
        Id?: number;
        CompanyId?: number;
        ParentId?: number;
        Name?: string;
        NameEn?: string;
        AddressId?: number;
        Spend?: boolean;
        Address?: IAddress;
        Code?: string;
        BranchNum?: number;
        GroupId?: number;
        Company?: ICompany;
        Phone?: string;
        Note?: string;
        CustomerParameters?: ICustomerParams;
        BranchProdSpendListType?: number;
        SupplierParams?: ISupplierParams;
        BankAccounts?: ICompanyBankAccount[];
        BranchAccounts?: ICompanyBranchAccount[];
    }

    export interface ICompanyBranchAccount {
        Id: number;
        BranchId?: number;
        Account?: string;
        AccType?: number;
    }

    export interface ICompanyBranchAccountView {
        Id: number;
        BranchId?: number;
        Account?: string;
        AccountName?: string;
        AccountTypeName?: string;
        AccType?: number;
        CompanyId?: number;
    }

    export interface IPerson {
        Id?: number;
        PersonSN?: string;
        PName?: string;
        FirstName?: string;
        LastName?: string;
        SecondName?: string;
        DateBorn?: Date;
        Phone?: string;
        Mobile?: string;
        WorkPhone?: string;
        Email?: string;
        CityId?: string;
        Description?: string;
        Note?: string;
        Citizenship?: number;
        Is_Rezident?: boolean;
        Sex?: number;
        Spend?: boolean;
        Unid?: string;
        Position?: string;
    }
    export interface IPhones {
        Id?: number;
        Unid?: string;
        PhoneType?: number;
        Phone?: string;
        Priority?: boolean;
        Note?: string;
    }
    export interface IEmail {
        Id?: number;
        Unid?: string;
        EmailType?: number;
        Email?: string;
        Note?: string;
    }
    export interface IWebPage {
        Id?: number;
        Unid?: string;
        WebPageType?: number;
        WebPage?: string;
        Note?: string;
    }
    export interface IAddress {
        Id?: number;
        CityId?: string;
        BlockId?: number;
        PostalCode?: string;
        FullAddress?: string;
        Location?: IGPSLocation;
    }
    export interface IPersonsAddress {
        Id?: number;
        AddressId?: number;
        Unid?: string;
        Address?: IAddress;
    }
    export interface iStaffParams {
        Person_id?: number;
        Dep_id?: number;
        Post_id?: number;
        Salary?: number;
        No_income_tax?: boolean;
        Cr_acc?: string;
        Db_acc?: string;
        Bank_id?: string;
        Bank_acc?: string;
        Staff_status?: number;
        Exempt_income_amount?: number;
        Exempt_income_percent?: number;
    }
    export interface IAccount {
        Acc?: string;
    }
    // ესენი გასატანი იქნება common-ში
    export interface ICity {
        CityId?: string;
        CityNameEng?: string;
        CityNu?: string;
        CountryId?: string;
        LDefault?: boolean;
        Spend?: boolean;
    }
    // *****************
}