/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface PersonsEditScope extends angular.IScope {
        formName: string;
        person: Apex.Crm.Interface.IPerson,
        personalGrid: Apex.Components.IApxGrid,
        branchId?: number,
        events: {
            refresh: Function,
            add: Function;
            exit: Function;
        }
    }

    export interface IPersonalModalData {
        existingPersonsId: Array<number>
    }

    export class PersonsModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: PersonsEditScope, private $translate, data: any, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, PersonalModal.$inject);

            this.initialisation();
            $scope.formName = "დამატება";
            $scope.branchId = data.branchId;
            this.getPersons(data.data);
             

        }

        initialisation = () => {
            this.$scope.personalGrid = this.initPersonalGrid()
            this.$scope.events = {
                refresh: this.refresh,
                add: this.savePerson,
                exit: this.exitForm
            }
        }
        getPersons = (existingData: Array<number>) => {
            this.crmService.getPersons().then(result => {
                var data: Array<Apex.Crm.Interface.IPerson> = result.Result;
                if (existingData != undefined && existingData != null && existingData.length > 0) {

                    var filteredData = new Array<Apex.Crm.Interface.IBranchPerson>();
                    for (var i = 0; i < data.length; i++) {
                        if (!_.some(existingData, (item) => {
                            return data[i].Id == item;
                        }))
                            filteredData.push(data[i]);
                    }
                    this.$scope.personalGrid.dataSource.data(filteredData);
                }
                else
                    this.$scope.personalGrid.dataSource.data(data);
            }).catch(error => { });
        }
        savePerson = () => {
            var selectedData = this.$scope.personalGrid.grid.select();
            for (var i = 0; i < selectedData.length; i++) {
                this.crmService.saveBranchPersons(<Apex.Crm.Interface.IBranchPerson>
                    {
                        BranchId: this.$scope.branchId,
                        Id: 0,
                        Person: {
                            Id: (<Apex.Crm.Interface.IPerson>this.$scope.personalGrid.grid.dataItem(selectedData[i])).Id
                        }
                    }).then(result => {
                        if (i == selectedData.length - 1)
                            this.$uibModalInstance.close(true);
                    }).catch(error => {
                        if (i == selectedData.length - 1)
                            this.$uibModalInstance.close(true);
                    });
            }
            this.$uibModalInstance.close(true);
        }
        initPersonalGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.branchPersonsDataSource(),
                    columns: this.PersonalGridColumns,
                    settings: {
                        editble: { disableEditRow: true}, height: 300, selectable: "multiple"
                    },
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: () => {

                            }
                        }
                    ]

                };

            return grid;
        }
        branchPersonsDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: []
            });
        }

        /// events
        refresh = () => {
            this.crmService.getPersons()
        }
        exitForm = () => {
            this.$uibModalInstance.close(false);
        }
        ///********* */
        PersonalGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "PersonSN", titleTranslated: "personalNumber", width: 90
            },
            {
                field: "FirstName", titleTranslated: "name", width: 100
            },
            {
                field: "LastName", titleTranslated: "lastname", width: 100
            }
        ];
    }
}
