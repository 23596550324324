/// <reference path='./../_reference.ts' />
module  Apex.Crm.Directives {
    "use strict";
    export class ApxAddress implements angular.IDirective {
        restrict = "EA";
        templateUrl = 'apx.address.html';
        scope = {
            Address:'=ngModel'
        };
        require = ['ngModel'];        
        controller = Apex.Crm.Controllers.AddressController.getName();
        link = (scope, element, attrs, controller) => {
        }

        public static factory() {
            var directive = () => {
                return new ApxAddress();
            };
            return directive;
        }
    }
}