/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface BranchEditScope extends angular.IScope {
        formName: string;
        branch: any;
        parentId?: number;
        option: {
            maskOption: {
                integerPositive: Components.IApxNumber
            }
        };
        events: {
            closeForm: Function,
            save?: Function
        }
        repositoryData: {
            groups?: any
        }
    }

    export class BranchModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: BranchEditScope, private $translate, data: any, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, PersonalModal.$inject);
            this.initialization();
            if (data.branch != undefined && data.branch != null) {
                $scope.formName = "რედაქტირება";
                $scope.branch = data.branch;
            } else {
                $scope.formName = "დამატება";
                $scope.branch = {
                    Id: 0,
                    ParentId: data.parentId,
                    Address: {}
                }
            }
            this.initRepositories();
            $timeout(()=>{
                $("#code").focus();
            });
        }
        initRepositories = () => {
            this.crmService.getDtAny("173c69c5-6e02-4c72-bfb0-5892d1f67373").then(result => {
                this.$scope.repositoryData.groups = result.Result;
            });
        }
        initialization = () => {
            this.$scope.repositoryData = {}
            this.$scope.events = {
                closeForm: this.closeForm,
                save: this.save
            }
            this.$scope.option = {
                maskOption: {
                    integerPositive: { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false }
                }
            }
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            if (this.validateForm()) {
                if (Object.getOwnPropertyNames(this.$scope.branch.Address).length == 0){
                    this.$scope.branch.Address = null;
                    this.$scope.branch.AddressId=null;
                }
                this.$uibModalInstance.close(this.$scope.branch);
            }
        }
        validateForm = (): boolean => {
            if (this.$scope.branch.Name == undefined || this.$scope.branch.Name == "" || this.$scope.branch.Name.trim() == "") {
                this.toaster.pop('error', "Error", "გთხოვთ შეავსოთ '" + this.$translate.instant('nameCaption') + "' ველი");
                return false;
            }
            return true;
        }
    }
}