/// <reference path='../_reference.ts' />
module Apex.Crm.Controllers {
    export interface PersonalScope extends angular.IScope {
        Components: {
            DBLookup: Components.IApxLookup,
            CRLookup: Components.IApxLookup,
            personalGrid: Apex.Components.IApxGrid,
            phoneGrid: Apex.Components.IApxGrid,
            mailGrid: Apex.Components.IApxGrid,
            webGrid: Apex.Components.IApxGrid,
            adressGrid: Apex.Components.IApxGrid,
            hide: boolean;
        },
        getPhoneType: Function,
        getEmailType: Function,
        getWebType: Function,
        getAddressCity: Function,
        getAddressRegion: Function,

        showRefreshInfo: boolean,
        cities: any[],
        region: any[],
        phoneTypes: any[],
        emailAndWebTypes: any[],
        banks?: any,
        departaments: any[],
        position: any[];
        status: any[],
        dataSource: any;
        showMode: boolean;
        model:
        {
            selectedPersonal: Apex.Crm.Interface.IPerson,
            StafParams?: Apex.Crm.Interface.iStaffParams,
            Debet: Apex.Crm.Interface.IAccount,
            Credit: Apex.Crm.Interface.IAccount,
        },
        events: {
            refresh: Function,
            getPersonalTabData: Function,
            PersonDetails: { add: Function, edit: Function, delete: Function },
            showOrHide: Function
        },
        deleteMode: boolean,
        editMode: boolean,
        halfPageBody?: number;
        personalDetailsGridSize?: number;
        countInputOptions: Components.IApxNumber;
        percentOptions: any;
    }

    export class PersonalController extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, Globals.toaster, 'crmService', '$log','$timeout'];
        constructor(private $scope: PersonalScope, private $translate, private toaster: any, private crmService: Apex.Crm.Services.Crm, $log, private $timeout) {
            super(arguments, PersonalController.$inject);

            $scope.$watch("pageBody", (newValue, oldValue, scope) => {
                $scope.halfPageBody = $("#pageBody").height() / 2;
                $log.log("#pageBody size is:", $scope.halfPageBody);
                $("#content-size").height($scope.halfPageBody);
                // $("#person-details").height(halfPageBody);
                $timeout(() => {
                    var detGrids = $("#person-details").find(".k-grid-content");
                    var outsize = $(".nav-tabs").height() + $(".k-grid-header").first().height();
                    $log.log("out size is:", outsize);
                    this.$scope.personalDetailsGridSize = $scope.halfPageBody - outsize;
                    for (var i = 0; i < detGrids.length; i++) {
                        $(detGrids[i]).height($scope.personalDetailsGridSize);
                    }
                });

            });

            $scope.getPhoneType = this.getPhoneType;
            $scope.getEmailType = this.getEmailType;
            $scope.getWebType = this.getWebType;
            $scope.getAddressCity = this.getAddressCity;
            $scope.getAddressRegion = this.getAddressRegion;


            this.getDepartments();
            this.getPost();
            this.getStatus();
            this.getBanks();
            crmService.getDtAny("04452674-5896-42d2-9303-6721b324b460").then(result => {
                this.$scope.phoneTypes = (result.Result);
            });
            crmService.getDtAny("abe9d238-997c-4cf2-834c-4fbd23192dba").then(result => {
                this.$scope.emailAndWebTypes = (result.Result);
            });
            crmService.getDtAny("e13b9cb4-9d87-4912-976a-18445b62e40c").then(result => {
                this.$scope.region = (result.Result);
            });
            crmService.getCities().then(result => {
                this.$scope.cities = (result.Result);
            });
            $scope.showRefreshInfo = true;
            $scope.showMode = false;
            $scope.deleteMode = true;
            $scope.editMode = true;
            $scope.Components = {
                DBLookup: this.initDBLookup(),
                CRLookup: this.initCRLookup(),
                personalGrid: this.initPersonalGrid(),
                phoneGrid: this.initPhoneGrid(),
                mailGrid: this.initmailGrid(),
                webGrid: this.initWebGrid(),
                adressGrid: this.initAdressGrid(),
                hide: true
            }

            $scope.model = {
                selectedPersonal: null,
                StafParams: {},
                Debet: {},
                Credit: {}
            };
            $scope.countInputOptions = {
                allowMinus: false,
                min: 0.00,
                decimals: 2,
                numberType: Components.NumberType.Decimal
            };
            $scope.percentOptions = {
                allowMinus: false,
                numeric: true,
                min: 0,
                numberType: Components.NumberType.Integer
            }
            $scope.events = {
                getPersonalTabData: (id, person_id) => {
                    this.crmService.GetStaffParams(person_id).then(res => {
                        if (res.Result) {
                            this.$scope.model.StafParams = res.Result;
                            this.$scope.model.Debet.Acc = this.$scope.model.StafParams.Db_acc;
                            this.$scope.model.Credit.Acc = this.$scope.model.StafParams.Cr_acc;
                            this.$scope.model.StafParams.Exempt_income_percent = this.$scope.model.StafParams.Exempt_income_percent * 100;

                        }
                        else {
                            this.$scope.model.StafParams = {};
                        }
                    });
                    this.crmService.getPersonsPhone(id).then(res => {
                        $scope.Components.phoneGrid.dataSource.data(res.Result);
                    });
                    this.crmService.getPersonsEmail(id).then(res => {
                        $scope.Components.mailGrid.dataSource.data(res.Result);
                    });
                    this.crmService.getPersonsWebPage(id).then(res => {
                        $scope.Components.webGrid.dataSource.data(res.Result);
                    });
                    this.crmService.getPersonsAdress(id).then(res => {
                        $scope.Components.adressGrid.dataSource.data(res.Result);

                        var convertedDatasource = [];
                        _.forEach(res.Result, (item: any) => {
                            convertedDatasource.push({
                                AddressId: item.Address.Id,
                                Id: item.Address.Id,
                                CityId: item.Address.CityId,
                                BlockId: item.Address.BlockId,
                                PostalCode: item.Address.PostalCode,
                                FullAddress: item.Address.FullAddress,
                                Location: item.Address.Location
                            })
                        });

                        $scope.Components.adressGrid.dataSource.data(convertedDatasource);

                    });
                },
                refresh: () => {
                    this.$scope.Components.personalGrid.gridView.showLoading();
                    crmService.getPersons().then(result => {
                        $scope.dataSource = result.Result;
                        $scope.Components.personalGrid.dataSource.data($scope.dataSource);
                        this.$scope.Components.personalGrid.gridView.hideLoading();
                    }).catch(r => toaster.pop(r));
                    $scope.Components.phoneGrid.dataSource.data([]);
                    $scope.Components.mailGrid.dataSource.data([]);
                    $scope.Components.webGrid.dataSource.data([]);
                    $scope.Components.adressGrid.dataSource.data([]);
                    this.$scope.showRefreshInfo = false;
                },

                PersonDetails: {

                    add: this.addPerson,
                    edit: this.editPerson,
                    delete: this.deletePerson
                },
                showOrHide: () => {
                    if (this.$scope.Components.hide == false) {
                        this.$scope.showMode = false;
                        var bodySize = $("#pageBody").innerHeight() - 25;
                        $('#content-size').animate({ height: bodySize }, 500);
                    }
                    else {
                        $('#content-size').animate({ height: this.$scope.halfPageBody }, 500);
                    }
                }
            };


        }


        addPerson = () => {
            this.openModal({});
        }

        editPerson = () => {
            //this.$scope.Components.personalGrid.
            this.openModal(this.$scope.model.selectedPersonal);
        }
        deletePerson = () => {
            var confirmationDialog = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            confirmationDialog.result.then(() => {
                this.crmService.deletePerson(this.$scope.model.selectedPersonal.Unid).then((result: any) => {
                    if (result.success === false)
                        return;

                    Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით წაიშალა') });
                    this.$scope.events.refresh();

                });
            })
        }

        openModal = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm('personal.modal.html', PersonalModal.getName(), data, Core.Utils.ModalSize.Large);
            dlg.result.then(result => {
                if (typeof result !== "undefined" && result) {
                    this.$scope.events.refresh();

                }
            });
        }

        initPersonalGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.PersonalGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: () => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.$scope.model.selectedPersonal = grid.selectedObject;
                                    if (this.$scope.model.selectedPersonal != null && this.$scope.model.selectedPersonal.Unid != undefined) {
                                        this.$scope.events.getPersonalTabData(this.$scope.model.selectedPersonal.Unid, this.$scope.model.selectedPersonal.Id);
                                    }
                                });

                                var selectedRow: any = grid.grid.dataItem(grid.grid.select());
                                if (selectedRow != null && selectedRow != undefined) {
                                    Core.Utils.Helper.safeApply(this.$scope, this.$scope.deleteMode = false);
                                    Core.Utils.Helper.safeApply(this.$scope, this.$scope.editMode = false);
                                }
                                else {
                                    Core.Utils.Helper.safeApply(this.$scope, this.$scope.deleteMode = true);
                                    Core.Utils.Helper.safeApply(this.$scope, this.$scope.editMode = true);
                                }
                            }
                        }
                    ]

                };

            return grid;
        }

        getDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                schema: {
                    model: {
                        id: 'Id',
                        fields: {
                            PersonSN: { type: "string" },
                            FirstName: { type: "string" },
                            LastName: { type: "string" },
                            PName: { type: "string" },
                            DateBorn: { type: "date" },
                            Position: { type: "string" },
                            Mobile: { type: "string" },
                            Description: { type: "string" },
                            Spend: { type: "boolean" }
                        }
                    }
                },
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],

            });
        }
        PersonalGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "PersonSN", titleTranslated: "personalNumber", width: 90
            },
            {
                field: "FirstName", titleTranslated: "name", width: 100
            },
            {
                field: "LastName", titleTranslated: "lastname", width: 100
            },
            {
                field: "PName", titleTranslated: "PName", width: 150
            },
            {
                field: "DateBorn", titleTranslated: "Bdate", format: Core.Kendo.Format.Date, width: 100
            },
            {
                field: "Position", titleTranslated: "Position", width: 150
            },
            {
                field: "Mobile", titleTranslated: "mobileN", width: 100
            },
            {
                field: "Description", titleTranslated: "Description", width: 170
            },
            {
                field: "Spend", titleTranslated: "Spend", width: 90, filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: this.$translate.instant('isSelected') },
                            { value: 0, text: this.$translate.instant('isNotSelected') }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                },
                template:
                `<div class="text-center"  ng-if="dataItem.Spend"><i class="fa fa-check text-muted" ></i></div>`
            }
        ];
        // თანამშრომლის პარამეტრები
        getDepartments() {
            this.crmService.getDepartments().then(r => {
                this.$scope.departaments = r.Result;
            });
        }
        getPost() {
            this.crmService.getPost().then(r => {
                this.$scope.position = r.Result;
            });
        }
        getStatus() {
            this.crmService.getFixValChildren('82F8DA53-EF85-4110-9EA4-A898BF6674EE').then(r => {
                this.$scope.status = r.Result;
            });
        }
        getBanks = () => {
            this.crmService.getBanks().then(r => {
                if (r) {
                    this.$scope.banks = r.Result;
                }
            });
        }
        initDBLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'Acc': { type: 'string' },
                    'Acc_nu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'Acc', titleTranslated: 'acc', width: 50 },
                    { field: 'Acc_nu', titleTranslated: 'nameCaption', width: 150 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.cardAccountsQueryUrl, dsModel),
                valueText: 'Acc_nu'
            };

            return lookUp;
        }
        initCRLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'Acc': { type: 'string' },
                    'Acc_nu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'Acc', titleTranslated: 'acc', width: 50 },
                    { field: 'Acc_nu', titleTranslated: 'nameCaption', width: 150 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.cardAccountsQueryUrl, dsModel),
                valueText: 'Acc_nu'
            };

            return lookUp;
        }
        // PhoneGrid//
        initPhoneGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getPhoneDatasource(),
                    columns: this.PhoneGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    parentScope: this.$scope
                };

            return grid;
        }
        getPhoneDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Phone: { type: "string" },
                            PhoneType: { type: "string" },
                            Note: { type: "string" },
                            Priority: { type: "boolean" }
                        }
                    }
                },
                data: []
            });
        }
        PhoneGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "Phone", titleTranslated: "phone", width: 80
            },
            {
                field: "PhoneType", titleTranslated: "phoneType", width: 80, template: '<span>{{options.parentScope.getPhoneType(dataItem.PhoneType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "note", width: 200
            },
            {
                field: "Priority", titleTranslated: "Default", width: 30, filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: this.$translate.instant('isSelected') },
                            { value: 0, text: this.$translate.instant('isNotSelected') }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                },
                template:
                `<div class="text-center"  ng-if="dataItem.Priority"><i class="fa fa-check text-success" ></i></div>`
            },

        ];
        getPhoneType = (x) => {
            var ret = _.find(this.$scope.phoneTypes, y => y.codeid == x);

            if (ret)
                return ret.hname;
            return "";
        }

        // MailGrid //
        initmailGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getMailDatasource(),
                    columns: this.MailGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    parentScope: this.$scope
                };

            return grid;
        }

        getMailDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Email: { type: "string" },
                            EmailType: { type: "string" },
                            Note: { type: "string" }
                        }
                    }
                },
                data: []
            });
        }
        MailGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "Email", titleTranslated: "email", width: 80
            },
            {
                field: "EmailType", titleTranslated: "mailType", width: 80, template: '<span>{{options.parentScope.getEmailType(dataItem.EmailType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "note", width: 200
            }
        ];

        getEmailType = (x) => {

            var ret = _.find(this.$scope.emailAndWebTypes, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return " ";
        }
        // WebGrid //
        initWebGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getWebDatasource(),
                    columns: this.WebGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    parentScope: this.$scope
                };
            return grid;
        }

        getWebDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            WebPage: { type: "string" },
                            WebPageType: { type: "string" },
                            Note: { type: "string" }
                        }
                    }
                },
                data: []
            });
        }
        WebGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "WebPage", titleTranslated: "webPage", width: 80
            },
            {
                field: "WebPageType", titleTranslated: "webPageType", width: 80, template: '<span>{{options.parentScope.getWebType(dataItem.WebPageType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "booknote", width: 200
            }
        ];
        getWebType = (x) => {

            var ret = _.find(this.$scope.emailAndWebTypes, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }
        // AdressGrid //
        initAdressGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getAdressDatasource(),
                    columns: this.AdressGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    parentScope: this.$scope
                };


            return grid;
        }

        getAdressDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            CityId: { type: "number" },
                            BlockId: { type: "number" },
                            PostalCode: { type: "string" },
                            FullAddress: { type: "string" }
                        }
                    }
                },
                data: []
            });
        }
        AdressGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "CityId", titleTranslated: "city", width: 150, template: '<span>{{options.parentScope.getAddressCity(dataItem.CityId)}}</span>'
            },
            {
                field: "BlockId", titleTranslated: "Region", width: 150, template: '<span>{{options.parentScope.getAddressRegion(dataItem.BlockId)}}</span>'
            },
            {
                field: "PostalCode", titleTranslated: "postalCode", width: 150
            },
            {
                field: "FullAddress", titleTranslated: "address", width: 150
            }
        ];
        getAddressCity = (x) => {

            var ret = _.find(this.$scope.cities, y => y.CityId == x);
            if (ret)
                return ret.CityNu;
            return "";
        }
        getAddressRegion = (x) => {

            var ret = _.find(this.$scope.region, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }
    }
}