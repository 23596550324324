/// <reference path='./_reference.ts' />
module Apex.Crm {
    'use strict';
    var apxCrm = new Apex.Core.Module('crm', ['apex.webUI.components','kendo.directives','bgDirectives'], Crm);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'crm',
                url: '^/crm',
                template: '<ui-view/>',
                navigation: { caption: 'crm', navigationClass: 'md md-home' },
                childRoutes: [
                    {
                        state: 'company',
                        url: '/company',
                        controller: Apex.Crm.Controllers.CompanyController.getName(),
                        templateUrl: "company.html",
                        navigation: {
                            caption: 'companies'
                        }
                    },
                    {
                        state: 'personal',
                        url: '/personal',
                        controller: Apex.Crm.Controllers.PersonalController.getName(),
                        templateUrl: "personal.html",
                        navigation: {
                            caption: 'Personal'
                        }  
                    }
                ]
            }
        ];

    apxCrm.regRoutes(routes, 'apex');
}