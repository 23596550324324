/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface BranchAccountEditScope extends angular.IScope {
        formName: string;
        branchAccount:Apex.Crm.Interface.ICompanyBranchAccount,
        repositoriesDates:{
            accountTypes:any,
            accounts:any
        },
        events:{
            lookupOnChange:Function,
            closeForm:Function,
            save:Function

        },
        model:{
            branchAccount?:any,
        }
        options:{
            accountsLookup:Components.IApxLookup
        }
    }

    export class BranchAccountModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: BranchAccountEditScope, private $translate, data:any, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, PersonalModal.$inject);

            this.initialisation();
            this.initRepositories();

            if (data.branchAccount != undefined && data.branchAccount != null) {
                $scope.formName = "რედაქტირება";
                $scope.branchAccount=data.branchAccount;
                $scope.model.branchAccount={
                    Acc:(<Apex.Crm.Interface.ICompanyBranchAccount>data.branchAccount).Account
                }
            }
            else {
                 $scope.branchAccount.BranchId=data.branchId;
                $scope.formName = "დამატება";
            }
            $timeout(()=>{
                $("#accountTypes").focus();
            });

        }

        initRepositories=()=>{
            this.crmService.getDtAny("7765bf6f-eff5-49aa-b621-0407bbce1205").then(result=>{
                this.$scope.repositoriesDates.accountTypes=result.Result;
            }).catch((error)=>this.toaster("error","Error",error.Message));
            
        }

        initialisation = () => {
            this.$scope.branchAccount=<Apex.Crm.Interface.ICompanyBranchAccount>{
                Account:"",
                AccType:null,
                BranchId:null,
                Id:null
            }
            this.$scope.model={};
            this.$scope.options={
                accountsLookup:this.accountLookupOptions()
                
            }
            this.$scope.repositoriesDates={
                accounts:[],
                accountTypes:[]
            };

            this.$scope.events={
                lookupOnChange:this.lookupOnChange,
                closeForm:()=>{
                    this.$uibModalInstance.close(false);
                },
                save:this.saveBranchAccount
            };

        }
        saveBranchAccount=()=>{
            this.crmService.saveCompanyBranchAccount(this.$scope.branchAccount).then(result=>{
                // if(result.result!=undefined)
                this.$uibModalInstance.close(true);
            });
        }
        lookupOnChange=()=>{
            this.$scope.branchAccount.Account=String(this.$scope.options.accountsLookup.value);
        }
        accountLookupOptions = () => {
            var pProductsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'Acc', width: 120, titleTranslated: 'acc' },
                        { field: 'Acc_nu', width: 150, titleTranslated: 'nameCaption' },
                        { field: 'acc_ne',  width: 150, titleTranslated: 'nameCaptionEn' },
                        { field: 'CurrencyCurrency_Code',  width: 40, titleTranslated: 'Currency' },
                        { field: 'AccountDetailsaddress',  width: 250, titleTranslated: 'address' },
                        { field: 'AccountDetailssn',  width: 70, titleTranslated: 'SN' },
                        { field: 'AccountDetailsowner_name', width: 150,  titleTranslated: 'company' }
                    ],
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.branchAccountQueryUrl),
                    valueID:'Acc',
                    valueText: 'Acc'
                }

            return pProductsLookup;
        }

        
    }
}
