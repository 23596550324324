/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface LeafLetModalScope extends angular.IScope {
        formName: string;
        events: {
            closeForm: Function,
            savePoint: Function
        }
        maps: {
            markers: any
        }
    }

    export class LeafLetModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', Globals.modalInstance];
        constructor(private $scope: LeafLetModalScope, private $translate, data: Apex.Crm.Interface.IAddress, private $uibModalInstance) {
            super(arguments, PersonalModal.$inject);
            $scope.maps = {
                markers: []
            }

            if (data != undefined && data != null) {
                $scope.formName = "რედაქტირება";
                if (data.Location == undefined)
                    data.Location = {};
                this.$scope.maps.markers.push({
                    layer: "cluster",
                    lat: data.Location.Latitude,
                    lng: data.Location.Longitude
                });
            }
            else
                $scope.formName = "დამატება";



            $scope.events = {
                closeForm: () => {
                    this.$uibModalInstance.close(false);
                },
                savePoint: () => {
                    this.$uibModalInstance.close(this.$scope.maps.markers.length>0?this.$scope.maps.markers:null);
                }
            }
        }
    }
}