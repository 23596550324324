/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {

    export interface IAddressSelectable extends Interface.IAddress {
        isSelected: boolean;
    }
    export interface PersonalEditScope extends angular.IScope {
        isEditMode: boolean;
        cancelMode: boolean;
        getPhoneType: Function;
        getEmailType: Function;
        getWebType: Function;
        getAddressCity: Function;
        getAddressRegion: Function;
        Components: {
            phoneGrid: Apex.Components.IApxGrid,
            mailGrid: Apex.Components.IApxGrid,
            webGrid: Apex.Components.IApxGrid,
            adressGrid: Apex.Components.IApxGrid,
            DBLookup: Components.IApxLookup,
            CRLookup: Components.IApxLookup,
            vatTypeDS: any,
            resident: any

        },

        datasources: { phones: any, mails: any, webpages: any, adresses: any };
        banks?: any,
        departaments: any[],
        position: any[];
        status: any[],
        nationality: any[],
        cities: any[],
        region: any[],
        phoneTypes: any[],
        emailAndWebTypes: any[],

        maps: {
            showMap?: boolean;
            markers?: any,
        };
        model:
        {
            PersonClone: any;
            Personal: Apex.Crm.Interface.IPerson,
            Phone?: Apex.Crm.Interface.IPhones,
            Mail?: Apex.Crm.Interface.IEmail,
            Web?: Apex.Crm.Interface.IWebPage,
            PersonsAddress?: Apex.Crm.Interface.IPersonsAddress,
            Address?: IAddressSelectable,
            Location: Apex.Crm.Interface.IGPSLocation,
            StafParams?: Apex.Crm.Interface.iStaffParams,
            stafDBAcc?:any,
            stafCRAcc?:any,
        },
        closeForm: any;
        formName: string;
        deletedPhoneIds: Array<Interface.IPhones>;
        deletedEmails: Array<Interface.IEmail>;
        deletedWebPages: Array<Interface.IWebPage>;
        deletedAdress: Array<Interface.IPersonsAddress>;
        newId: number;
        ischanged: boolean;
        addressID: any;
        modalInstance: any;
        countInputOptions: Components.IApxNumber;
        percentOptions: Components.IApxNumber;
        events: {
            save: Function,
            removeMarker: Function,
            bankMask: Function,
        }
    }

    export class PersonalModal extends Core.Controller {
        public static $inject = [
            Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster, "$timeout", 'leafletData'
        ];
        constructor(private $scope: PersonalEditScope, private $translate, data: any, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, public $timeout, private leafletData) {
            super(arguments, PersonalModal.$inject);

            $timeout(() => {
                $(document).ready(function () {
                    $('.english_only').on("paste", function (e) {
                        e.preventDefault();
                    });
                });
            });

            $scope.getPhoneType = this.getPhoneType;
            $scope.getEmailType = this.getEmailType;
            $scope.getWebType = this.getWebType;
            $scope.getAddressCity = this.getAddressCity;
            $scope.getAddressRegion = this.getAddressRegion;
            crmService.getDtAny("04452674-5896-42d2-9303-6721b324b460").then(result => {
                this.$scope.phoneTypes = (result.Result);
            });
            crmService.getDtAny("abe9d238-997c-4cf2-834c-4fbd23192dba").then(result => {
                this.$scope.emailAndWebTypes = (result.Result);
            });
            crmService.getDtAny("e78ada33-da2f-4f75-a40c-56f753447950").then(result => {
                this.$scope.nationality = (result.Result);
            });
            crmService.getDtAny("e13b9cb4-9d87-4912-976a-18445b62e40c").then(result => {
                this.$scope.region = (result.Result);
            });
            crmService.getCities().then(result => {
                this.$scope.cities = (result.Result);
            });
            if (!data.PersonSN) {
                $scope.formName = "პერსონალის დამატება";
            }
            else {
                $scope.formName = "პერსონალის რედაქტირება";
            }
            $scope.model = {
                PersonClone: jQuery.extend(true, {}, data),
                Personal: data,
                Phone: {},
                Mail: {},
                Web: {},
                PersonsAddress: {},
                Location: {},
                StafParams: {}
            };
            $scope.countInputOptions = {
                allowMinus: false,
                min: 0.00,
                decimals: 2,
                numberType: Components.NumberType.Decimal
            };
            $scope.percentOptions = {
                allowMinus: false,
                min: 0,
                decimals: 0,
                numberType: Components.NumberType.Integer
            }
            $scope.newId = -1;
            $scope.ischanged = false;
            $scope.isEditMode = false;
            $scope.datasources = { phones: [], mails: [], webpages: [], adresses: [] };
            $scope.deletedEmails = [];
            $scope.deletedPhoneIds = [];
            $scope.deletedWebPages = [];
            $scope.deletedAdress = [];
            $scope.Components = {
                phoneGrid: this.initPhoneGrid(),
                mailGrid: this.initmailGrid(),
                webGrid: this.initWebGrid(),
                adressGrid: this.initAdressGrid(),
                DBLookup: this.initDBLookup(),
                CRLookup: this.initCRLookup(),
                vatTypeDS: [{ val: 0, title: "მამრობითი" }, { val: 1, title: "მდედრობითი" }],
                resident: [{ val: 1, title: "რეზიდენტი" }, { val: 0, title: "არარეზიდენტი" }]

            };

            this.getDepartments();
            this.getPost();
            this.getStatus();
            this.$scope.maps = {
                showMap: false,
                markers: []
            };

            $timeout(() => {
                $('.k-button.k-button-icontext.k-grid-edit-row').click(() => {

                    $scope.model.Phone = this.$scope.Components.phoneGrid.grid.dataItem(this.$scope.Components.phoneGrid.grid.select()).toJSON();

                    $scope.model.Mail = this.$scope.Components.mailGrid.grid.dataItem(this.$scope.Components.mailGrid.grid.select()).toJSON();

                    $scope.model.Web = this.$scope.Components.webGrid.grid.dataItem(this.$scope.Components.webGrid.grid.select()).toJSON();

                    var selectedAddress: any = this.$scope.Components.adressGrid.grid.dataItem(this.$scope.Components.adressGrid.grid.select()).toJSON();

                    if (selectedAddress !== null) {
                        $scope.model.Address = selectedAddress;
                        if (this.$scope.model.Address.Location == null) {
                            this.$scope.model.Address.Location = {};
                        }
                        this.$scope.maps.markers.push({
                            layer: "cluster",
                            lat: this.$scope.model.Address.Location.Latitude,
                            lng: this.$scope.model.Address.Location.Longitude,
                            message: "<h4 style='color:black;'>" + this.$scope.model.Address.FullAddress + "</h4>"
                        });
                    }
                    $scope.isEditMode = true;
                });
            });

            if ($scope.model.Personal.PersonSN != null && $scope.model.Personal.Unid != undefined) {
                this.crmService.GetStaffParams(this.$scope.model.Personal.Id).then(res => {
                    if (res.Result) {
                        this.$scope.model.StafParams = res.Result;
                        this.$scope.model.StafParams.Exempt_income_percent = this.$scope.model.StafParams.Exempt_income_percent * 100;

                    }
                    else {
                        this.$scope.model.StafParams = {};
                    }

                });
                this.crmService.getPersonsPhone(this.$scope.model.Personal.Unid).then(res => {
                    $scope.datasources.phones = res.Result;
                    $scope.Components.phoneGrid.dataSource.data(res.Result);
                });
                this.crmService.getPersonsEmail(this.$scope.model.Personal.Unid).then(res => {
                    $scope.datasources.mails = res.Result;
                    $scope.Components.mailGrid.dataSource.data(res.Result);
                });
                this.crmService.getPersonsWebPage(this.$scope.model.Personal.Unid).then(res => {
                    $scope.datasources.webpages = res.Result;
                    $scope.Components.webGrid.dataSource.data(res.Result);
                });
                this.crmService.getPersonsAdress(this.$scope.model.Personal.Unid).then(res => {
                    var convertedDatasource = [];
                    _.forEach(res.Result, (item: any) => {
                        convertedDatasource.push({
                            AddressId: item.Address.Id,
                            Id: item.Id,
                            CityId: item.Address.CityId,
                            BlockId: item.Address.BlockId,
                            PostalCode: item.Address.PostalCode,
                            FullAddress: item.Address.FullAddress,
                            Location: item.Address.Location
                        });
                    });
                    $scope.datasources.adresses = convertedDatasource;
                    $scope.Components.adressGrid.dataSource.data(convertedDatasource);
                });
            };

            $timeout(() => {
                $('#addAddress,#editaddress').on('click', (e) => {
                    this.$scope.maps.showMap = false;

                    // setTimeout(() => {
                        Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.$scope.maps.showMap = true;

                            //console.log($('.k-window')); 
                            //$('.k-window').first().css("height", "400px !important");
                        // });
                    });
                });


                $('#multiDeletePhonesGrid').on('click', () => {
                    this.phoneDelete();
                });
                $('#multiDeleteMailGrid').on('click', () => {
                    this.emailDelete();
                });
                $('#multiDeleteWebGrid').on('click', () => {
                    this.webPageDelete();
                });
                $('#multiDeleteAdressGrid').on('click', () => {
                    this.adressDelete();
                });


            }, 300);

            $scope.events = {
                bankMask: (evn) => {
                    if (!/^[ A-Za-z_0-9]*$/i.test(evn.key.trim()))
                        evn.preventDefault();
                    if (/^[ ]*$/i.test(evn.key.trim()))
                        evn.preventDefault();
                },
                save: () => {
                    if (this.$scope.model.Personal.PersonSN == null || this.$scope.model.Personal.FirstName == null) {
                        this.toaster.pop('error', 'ყურადღება', 'გთხოვთ შეავსოთ აუცილებელი ველები!');
                        return;
                    }
                    if (!this.$scope.model.PersonClone.PersonSN) {
                        this.crmService.SavePersons(this.$scope.model.Personal).then((res) => {
                            if (res.success != false && res.Result > 0) {
                                if (res.Unid != undefined) {
                                    _.forEach(this.$scope.datasources.phones, (itemPhone: Interface.IPhones) => {
                                        itemPhone.Unid = res.Unid;
                                        this.crmService.SavePersonsPhones(itemPhone);
                                    });
                                    _.forEach(this.$scope.datasources.mails, (itemEmail: Interface.IEmail) => {
                                        itemEmail.Unid = res.Unid;
                                        this.crmService.SavePersonsEmail(itemEmail);
                                    });
                                    _.forEach(this.$scope.datasources.webpages, (itemWeb: Interface.IWebPage) => {
                                        itemWeb.Unid = res.Unid;
                                        this.crmService.SavePersonsWeb(itemWeb);
                                    });
                                    _.forEach(this.$scope.datasources.adresses, (itemAdress: IAddressSelectable) => {
                                        this.crmService.saveAddressAsync(itemAdress).then((res_address) => {
                                            if (res_address.Result != undefined) {
                                                var personAddresss = <Apex.Crm.Interface.IPersonsAddress>{
                                                    Unid: res.Unid,
                                                    AddressId: res_address.Result
                                                };

                                                if (itemAdress.isSelected == true)
                                                    this.crmService.SavePersonsAdress(personAddresss);
                                            }
                                        });
                                    });

                                }
                                this.$scope.model.StafParams.Person_id = res.Result;
                                this.$scope.model.StafParams.Exempt_income_percent = this.$scope.model.StafParams.Exempt_income_percent / 100;
                                this.crmService.SaveStaffParams(this.$scope.model.StafParams);

                                this.toaster.pop('success', 'ყურადღება', 'წარმატებით დაემატა');
                                delete this.$scope.model.Personal;
                                this.$uibModalInstance.close(true);
                            }
                        });

                    }
                    else {
                        this.crmService.SavePersons(this.$scope.model.Personal).then((res) => {
                            if (res.success != false && res.Result > 0) {
                                if (res.Unid != undefined) {
                                    _.forEach(this.$scope.datasources.phones, (itemPhone: Interface.IPhones) => {
                                        itemPhone.Unid = res.Unid;
                                        this.crmService.SavePersonsPhones(itemPhone);
                                    });
                                    _.forEach(this.$scope.datasources.mails, (itemEmail: Interface.IEmail) => {
                                        itemEmail.Unid = res.Unid;
                                        this.crmService.SavePersonsEmail(itemEmail);
                                    });
                                    _.forEach(this.$scope.datasources.webpages, (itemWeb: Interface.IWebPage) => {
                                        itemWeb.Unid = res.Unid;
                                        this.crmService.SavePersonsWeb(itemWeb);
                                    });
                                    _.forEach(this.$scope.datasources.adresses, (itemAdress: IAddressSelectable) => {
                                        this.crmService.saveAddressAsync(itemAdress).then((res_address) => {
                                            if (res_address.Result != undefined) {
                                                var personAddresss = <Apex.Crm.Interface.IPersonsAddress>{
                                                    Unid: res.Unid,
                                                    AddressId: res_address.Result
                                                };
                                                if (itemAdress.isSelected == true)
                                                    this.crmService.SavePersonsAdress(personAddresss);
                                            }
                                        });
                                    });
                                    this.$scope.model.StafParams.Person_id = res.Result;
                                    this.$scope.model.StafParams.Exempt_income_percent = this.$scope.model.StafParams.Exempt_income_percent / 100;
                                    this.$scope.model.StafParams.Db_acc=this.$scope.model.stafDBAcc.Acc;
                                    this.$scope.model.StafParams.Cr_acc=this.$scope.model.stafCRAcc.Acc;
                                    
                                    this.crmService.SaveStaffParams(this.$scope.model.StafParams);
                                    this.toaster.pop('success', 'ყურადღება', 'რედაქტირება წარმატებით დასრულდა');
                                    delete this.$scope.model.Personal;
                                }
                                this.$uibModalInstance.close(true);
                            }
                        });
                        this.$scope.ischanged = false;
                    }

                    var phoneIdsForDelete = _.map(this.$scope.deletedPhoneIds, (phone: Interface.IPhones) => { return phone.Id });
                    this.crmService.deletePersonalPhones(phoneIdsForDelete);

                    var emaildsForDelete = _.map(this.$scope.deletedEmails, (email: Interface.IEmail) => { return email.Id });
                    this.crmService.deletePersonalEmails(emaildsForDelete);

                    var webPageIdsForDelete = _.map(this.$scope.deletedWebPages, (web: Interface.IWebPage) => { return web.Id });
                    this.crmService.deletePersonalWebPages(webPageIdsForDelete);

                    var addressIdsForDelete = _.map(this.$scope.deletedAdress, (address: Interface.IPersonsAddress) => { return address.Id });

                    this.crmService.deletePersonalAddress(addressIdsForDelete);

                },
                removeMarker: () => {
                    this.$scope.maps.markers = [];
                }
            };
            $scope.closeForm = this.closeForm;
            this.getBanks();
        }
        getDepartments() {
            this.crmService.getDepartments().then(r => {
                this.$scope.departaments = r.Result;
            });
        }
        getPost() {
            this.crmService.getPost().then(r => {
                this.$scope.position = r.Result;
            });
        }
        getStatus() {
            this.crmService.getFixValChildren('82F8DA53-EF85-4110-9EA4-A898BF6674EE').then(r => {
                this.$scope.status = r.Result;
            });
        }
        getBanks = () => {
            this.crmService.getBanks().then(r => {
                if (r) {
                    this.$scope.banks = r.Result;
                }
            });
        }
        // PhoneGrid//
        initPhoneGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getDatasource(),
                    columns: this.PhoneGridColumns,
                    settings: {
                        selectable: "multiple",
                        gridButtons: {
                            add: { enabled: true }, multiDelete: { enabled: true, id: 'PhonesGrid' }, edit: { enabled: true, id: 'phone' }
                        },
                        editble: {
                            popupTemplateID: 'personal_phones_popup_editor',
                            popupWidth: 500
                        },
                        height: 380
                    },
                    parentScope: this.$scope,
                    gridEvents: [this.getAddPhoneEvent(), this.getPhonesCancelEvent()]
                };

            return grid;
        }
        getDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Phone: { editable: false },
                            PhoneType: { editable: false },
                            Note: { editable: false },
                            Priority: { editable: false }
                        }
                    }
                },
                data: []
            });
        }


        PhoneGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "Phone", titleTranslated: "phone", width: 80
            },
            {
                field: "PhoneType", titleTranslated: "phoneType", width: 80, template: '<span>{{options.parentScope.getPhoneType(dataItem.PhoneType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "note", width: 200
            },
            {
                field: "Priority", titleTranslated: "Default", width: 30, template:
                `<div class="text-center"  ng-if="dataItem.Priority"><i class="fa fa-check text-success" ></i></div>`
            },

        ];
        getPhoneType = (x) => {

            var ret = _.find(this.$scope.phoneTypes, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }

        private getAddPhoneEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addEventFunctionality
                };
            return addEvent;
        }

        addEventFunctionality = (e) => {
            this.$scope.ischanged = true;
            if (this.$scope.isEditMode) {
                for (var i = 0; i < this.$scope.datasources.phones.length; i++) {
                    if (this.$scope.datasources.phones[i].Id == this.$scope.model.Phone.Id) {
                        this.$scope.datasources.phones[i] = this.$scope.model.Phone;
                        break;
                    }
                }
                this.$scope.Components.phoneGrid.dataSource.data(this.$scope.datasources.phones);
            }
            else {
                this.$scope.datasources.phones.unshift(this.$scope.model.Phone);
                this.$scope.model.Phone.Id = this.$scope.newId--;
                this.$scope.Components.phoneGrid.dataSource.data(this.$scope.datasources.phones);
            }
            this.$scope.isEditMode = false;
            delete this.$scope.model.Phone;
        }

        private getPhonesCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.phonesCancelEventFunctionality
                };
            return cancelEvent;
        }

        phonesCancelEventFunctionality = () => {
            delete this.$scope.model.Phone;
        };

        phoneDelete = () => {

            var selectedPhones = this.$scope.Components.phoneGrid.grid.select();
            var length: number = selectedPhones.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            }
            else {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("multiDeleteConfirmation") + " " + selectedPhones.length);
            }
            r.result.then(() => {
                _.forEach(selectedPhones, (item) => {
                    let obj = this.$scope.Components.phoneGrid.grid.dataItem(item);
                    let phone: Interface.IPhones = obj.toJSON();
                    this.$scope.Components.phoneGrid.grid.dataSource.remove(obj);

                    this.$scope.Components.phoneGrid.grid.dataSource.sync();
                    this.$scope.deletedPhoneIds.push(phone);
                    var res = _.findIndex(this.$scope.datasources.phones, (x: Interface.IPhones) => x.Id == phone.Id);
                    this.$scope.datasources.phones.splice(res, 1);
                });
            });
        }



        // MailGrid //
        initmailGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getMailDatasource(),
                    columns: this.MailGridColumns,
                    settings: {
                        selectable: "multiple",
                        gridButtons: {
                            add: { enabled: true }, multiDelete: { enabled: true, id: 'MailGrid' }, edit: { enabled: true, id: 'mail' }
                        },
                        editble: {
                            popupTemplateID: 'personal_emails_popup_editor',
                            popupWidth: 500
                        },
                        height: 380
                    },
                    parentScope: this.$scope,
                    gridEvents: [this.getAddmailEvent(), this.getMailCancelEvent()]
                };

            return grid;
        }

        getMailDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Email: { editable: false },
                            Note: { editable: false },
                            EmailType: { editable: false }
                        }
                    }
                },
                data: []
            });
        }
        MailGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "Email", titleTranslated: "email", width: 80
            },
            {
                field: "EmailType", titleTranslated: "mailType", width: 80, template: '<span>{{options.parentScope.getEmailType(dataItem.EmailType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "note", width: 200
            }
        ];
        getEmailType = (x) => {

            var ret = _.find(this.$scope.emailAndWebTypes, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }

        private getAddmailEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addMailEventFunctionality
                };
            return addEvent;
        }


        addMailEventFunctionality = (e) => {
            this.$scope.ischanged = true;
            if (this.$scope.isEditMode) {
                for (var i = 0; i < this.$scope.datasources.mails.length; i++) {
                    if (this.$scope.datasources.mails[i].Id == this.$scope.model.Mail.Id) {
                        this.$scope.datasources.mails[i] = this.$scope.model.Mail;
                        break;
                    }
                }
                this.$scope.Components.mailGrid.dataSource.data(this.$scope.datasources.mails);
            }
            else {
                this.$scope.datasources.mails.unshift(this.$scope.model.Mail);
                this.$scope.model.Mail.Id = this.$scope.newId--;
                this.$scope.Components.mailGrid.dataSource.data(this.$scope.datasources.mails);
            }
            this.$scope.isEditMode = false;
            delete this.$scope.model.Mail;

        }

        private getMailCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.mailCancelEventFunctionality
                };
            return cancelEvent;
        }

        mailCancelEventFunctionality = () => {
            delete this.$scope.model.Mail;
        };


        emailDelete = () => {

            var selectedEmails = this.$scope.Components.mailGrid.grid.select();
            var length: number = selectedEmails.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            }
            else {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("multiDeleteConfirmation") + " " + selectedEmails.length);
            }
            r.result.then(() => {
                _.forEach(selectedEmails, (item) => {
                    let obj = this.$scope.Components.mailGrid.grid.dataItem(item);
                    var email: Interface.IEmail = obj.toJSON();
                    this.$scope.Components.mailGrid.grid.dataSource.remove(obj);
                    this.$scope.Components.mailGrid.grid.dataSource.sync();
                    this.$scope.deletedEmails.push(email);
                    var res = _.findIndex(this.$scope.datasources.mails, (x: Interface.IEmail) => x.Id == email.Id);
                    this.$scope.datasources.mails.splice(res, 1);
                });
            });
        }




        // WebGrid //
        initWebGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getWebDatasource(),
                    columns: this.WebGridColumns,
                    settings: {
                        selectable: "multiple",
                        gridButtons: {
                            add: { enabled: true }, multiDelete: { enabled: true, id: 'WebGrid' }, edit: { enabled: true, id: 'web' }
                        },
                        editble: {
                            popupTemplateID: 'personal_Web_popup_editor',
                            popupWidth: 500
                        },
                        height: 380
                    },
                    parentScope: this.$scope,
                    gridEvents: [this.getAddWebEvent(), this.getWebCancelEvent()]
                };


            return grid;
        }

        getWebDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            WebPage: { editable: false },
                            WebPageType: { editable: false },
                            Note: { editable: false }
                        }
                    }
                },
                data: []
            });
        }
        WebGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "WebPage", titleTranslated: "webPage", width: 80
            },
            {
                field: "WebPageType", titleTranslated: "webPageType", width: 80, template: '<span>{{options.parentScope.getWebType(dataItem.WebPageType)}}</span>'
            },
            {
                field: "Note", titleTranslated: "booknote", width: 200
            }
        ];
        getWebType = (x) => {

            var ret = _.find(this.$scope.emailAndWebTypes, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }
        private getAddWebEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addWebEventFunctionality
                };
            return addEvent;
        }

        addWebEventFunctionality = (e) => {
            this.$scope.ischanged = true;
            if (this.$scope.isEditMode) {
                for (var i = 0; i < this.$scope.datasources.webpages.length; i++) {
                    if (this.$scope.datasources.webpages[i].Id == this.$scope.model.Web.Id) {
                        this.$scope.datasources.webpages[i] = this.$scope.model.Web;
                        break;
                    }
                }
                this.$scope.Components.webGrid.dataSource.data(this.$scope.datasources.webpages);
            }
            else {
                this.$scope.datasources.webpages.unshift(this.$scope.model.Web);
                this.$scope.model.Web.Id = this.$scope.newId--;
                this.$scope.Components.webGrid.dataSource.data(this.$scope.datasources.webpages);
            }
            this.$scope.isEditMode = false;
            delete this.$scope.model.Web;
        }

        private getWebCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.webCancelEventFunctionality
                };
            return cancelEvent;
        }

        webCancelEventFunctionality = () => {
            delete this.$scope.model.Web;
        };

        webPageDelete = () => {

            var selectedWebPages = this.$scope.Components.webGrid.grid.select();
            var length: number = selectedWebPages.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            }
            else {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("multiDeleteConfirmation") + " " + selectedWebPages.length);
            }
            r.result.then(() => {
                _.forEach(selectedWebPages, (item) => {
                    let obj = this.$scope.Components.webGrid.grid.dataItem(item);
                    let webPage: Interface.IWebPage = obj.toJSON();
                    this.$scope.Components.webGrid.grid.dataSource.remove(obj);
                    this.$scope.Components.webGrid.grid.dataSource.sync();
                    this.$scope.deletedWebPages.push(webPage);
                    var res = _.findIndex(this.$scope.datasources.webpages, (x: Interface.IWebPage) => x.Id == webPage.Id);
                    this.$scope.datasources.webpages.splice(res, 1);
                });
            });
        }

        // AdressGrid //
        initAdressGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getAdressDatasource(),
                    columns: this.AdressGridColumns,
                    settings: {
                        selectable: "multiple",
                        gridButtons: {
                            add: { enabled: true, id: 'Address' }, multiDelete: { enabled: true, id: 'AdressGrid' }, edit: { enabled: true, id: 'address' }
                        },
                        editble: {
                            popupTemplateID: 'personal_adress_popup_editor',
                            popupWidth: 1200
                        },
                        height: 380
                    },
                    parentScope: this.$scope,
                    gridEvents: [this.getAddAdressEvent(), this.getAdressCancelEvent()]
                };
            return grid;
        }

        getAdressDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                aggregate: [
                    { field: "Id", aggregate: "count" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            CityId: { editable: false },
                            BlockId: { editable: false },
                            PostalCode: { editable: false },
                            FullAddress: { editable: false }
                        }
                    }
                },
                data: []

            });
        }
        AdressGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "CityId", titleTranslated: "city", width: 150, template: '<span>{{options.parentScope.getAddressCity(dataItem.CityId)}}</span>'
            },
            {
                field: "BlockId", titleTranslated: "Region", width: 150, template: '<span>{{options.parentScope.getAddressRegion(dataItem.BlockId)}}</span>'
            },
            {
                field: "PostalCode", titleTranslated: "postalCode", width: 150
            },
            {
                field: "FullAddress", titleTranslated: "address", width: 150
            }
        ];
        getAddressCity = (x) => {

            var ret = _.find(this.$scope.cities, y => y.CityId == x);
            if (ret)
                return ret.CityNu;
            return "";
        }
        getAddressRegion = (x) => {

            var ret = _.find(this.$scope.region, y => y.codeid == x);
            if (ret)
                return ret.hname;
            return "";
        }
        closeForm = () => {
            if (this.$scope.ischanged == true) {
                var confirmationDialog = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("saveConfirmation"));

                confirmationDialog.result.then((result) => {
                    this.$scope.events.save();
                }, (test) => {
                    delete this.$scope.model.Personal;
                    this.$uibModalInstance.close(false);
                });
            }
            else {
                delete this.$scope.model.Personal;
                this.$uibModalInstance.close(false);
            }
        }

        private getAddAdressEvent(): Components.IEvent {
            var addEventType: Components.EventType = Components.EventType.Save;
            var addEvent: Components.IEvent =
                {
                    eType: addEventType,
                    eFunction: this.addAdressEventFunctionality
                };
            return addEvent;
        }


        addAdressEventFunctionality = (e) => {
            this.$scope.ischanged = true;
            this.$scope.model.Address.isSelected = true;
            if (this.$scope.maps.markers.length > 0 && this.$scope.maps.markers[0].lat !== undefined) {
                this.$scope.model.Location.Latitude = this.$scope.maps.markers[0].lat;
                this.$scope.model.Location.Longitude = this.$scope.maps.markers[0].lng;
                if (this.$scope.model.Location !== null)
                    this.$scope.model.Address.Location = this.$scope.model.Location;
            }

            if (this.$scope.isEditMode) {
                for (var i = 0; i < this.$scope.datasources.adresses.length; i++) {
                    if (this.$scope.datasources.adresses[i].Id == this.$scope.model.Address.Id) {
                        this.$scope.datasources.adresses[i] = this.$scope.model.Address;
                        break;
                    }
                }
                this.$scope.Components.adressGrid.dataSource.data(this.$scope.datasources.adresses);
            }
            else {
                this.$scope.datasources.adresses.unshift(this.$scope.model.Address);
                this.$scope.model.Address.Id = this.$scope.newId--;
                this.$scope.Components.adressGrid.dataSource.data(this.$scope.datasources.adresses);
            }
            this.$scope.isEditMode = false;
            delete this.$scope.model.Address;
            this.$scope.maps.markers = [];
        }

        private getAdressCancelEvent(): Components.IEvent {
            var cancelEventType: Components.EventType = Components.EventType.Cancel;
            var cancelEvent: Components.IEvent =
                {
                    eType: cancelEventType,
                    eFunction: this.adressCancelEventFunctionality
                };
            return cancelEvent;
        }

        adressCancelEventFunctionality = () => {
            this.$scope.maps.markers = [];
            delete this.$scope.model.Address;
        };
        adressDelete = () => {

            var selectedAddress = this.$scope.Components.adressGrid.grid.select();
            var length: number = selectedAddress.length;
            if (length === 0)
                return;

            var r: any;
            if (length === 1) {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("deleteConfirmation"));
            }
            else {
                var r = Core.Utils.ModalForm.openConfirmation(this.$translate.instant("multiDeleteConfirmation") + " " + selectedAddress.length);
            }
            r.result.then(() => {
                _.forEach(selectedAddress, (item) => {
                    let obj = this.$scope.Components.adressGrid.grid.dataItem(item);
                    let personAddress: Interface.IPersonsAddress = obj.toJSON();
                    this.$scope.Components.adressGrid.grid.dataSource.remove(obj);
                    this.$scope.Components.adressGrid.grid.dataSource.sync();
                    this.$scope.deletedAdress.push(personAddress);
                    var res = _.findIndex(this.$scope.datasources.adresses, (x: Interface.IAddress) => x.Id == personAddress.Id);
                    this.$scope.datasources.adresses.splice(res, 1);
                });
            });
        }





        // თანამშრომლის პარამეტრები
        initDBLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'Acc': { type: 'string' },
                    'Acc_nu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'Acc', titleTranslated: 'acc', width: 50 },
                    { field: 'Acc_nu', titleTranslated: 'nameCaption', width: 150 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.cardAccountsQueryUrl, dsModel),
                valueText: 'Acc_nu'
            };

            return lookUp;
        }
        initCRLookup = (): Components.IApxLookup => {
            var dsModel = {
                fields: {
                    'Acc': { type: 'string' },
                    'Acc_nu': { type: 'string' }
                }
            }
            var lookUp: Components.IApxLookup = {
                columns: [
                    { field: 'Acc', titleTranslated: 'acc', width: 50 },
                    { field: 'Acc_nu', titleTranslated: 'nameCaption', width: 150 }
                ],
                dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.crmService.cardAccountsQueryUrl, dsModel),
                valueText: 'Acc_nu'
            };

            return lookUp;
        }
    }
}