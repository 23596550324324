/// <reference path='../_reference.ts' />

module Apex.Crm.Controllers {

    class TResult {
        status: number;
    }
    export interface CrmTabScope extends angular.IScope {
        Components: {
            branchAccountParamGrid?: Apex.Components.IApxGrid,
            bankAccountGrid?: Apex.Components.IApxGrid,
            branchPersonsGrid?: Apex.Components.IApxGrid
        };
        tabControl: {
            selectedTabPageIndex?: number;
            shouldPrevent?: boolean,
            selectedTabIndex?: number,
            tabEvents: {
                select: Function,
                deselect: Function
            }
        };
        settings?: {
            isTabEditable?: boolean,
            company?: {
                selectedCompany?: Apex.Crm.Interface.ICompany
            },
            branch?: {
                selectedBranchId?: number
            }
            supplayerParams?: {
                cash?: Apex.Crm.Interface.ISupplierParams,
                original?: Apex.Crm.Interface.ISupplierParams
            }
            customerParams?: {
                cash?: Apex.Crm.Interface.ICustomerParams,
                original?: Apex.Crm.Interface.ICustomerParams
            }
            branchScheduler?: {
                cash?: Apex.Crm.Interface.IBranchSchedule,
                original?: any
            }
        };
        days: any[];
        repositoriesDates: {
            customerTypeData?: any[];
            customerCategoryData?: any[];
            productTypeData?: any[];
        };

        option: {
            maskOption: {
                integerPositive: Components.IApxNumber,
                decimal4Positive: Components.IApxNumber,
                decimal2Positive: Components.IApxNumber
            }
        }
        events: {
            menuOpen: Function,
            refresh: Function,
            branchAccount: {
                add: Function,
                edit: Function,
                delete: Function
            },
            bankAccount: {
                add: Function,
                edit: Function,
                delete: Function
            },
            personal: {
                add: Function,
                addPerson: Function,
                delete: Function
            },
            branchScheduler: {
                change: Function
            },
            save: Function,
            lazyData: {
                getCustomerTypeData: Function,
                getCustomerCategory: Function,
                getProductType: Function
            }
        }
    }
    export class CrmTabController extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.translate, 'crmService', 'commonService', '$attrs', '$q', '$timeout'];
        constructor(private $scope: CrmTabScope, private toaster: any, private $translate, private crmService: Apex.Crm.Services.Crm,
            private commonService: Apex.Operations.Services.Common, private $attrs, private $q, private $timeout) {
            super(arguments, CompanyController.$inject);
            this.initialisation();
        }

        getCustomerCategory = () => {
            return this.commonService.getDTAny("AB4F6F14-CE84-48A4-BF4E-E1B697D03302")
        }
        getCustomerTypeData = () => {
            return this.commonService.getDTAny("BA6B4A21-5E74-4EF4-BACA-CAB3B4444DB6");
        }
        getProductType = () => {
            return this.commonService.getDTAny("A9F55A72-E929-477A-A6FB-EBC958C69E97");
        }

        initialisation = () => {
            this.$scope.events = {
                lazyData: {
                    getCustomerCategory: this.getCustomerCategory,
                    getCustomerTypeData: this.getCustomerTypeData,
                    getProductType: this.getProductType
                },
                menuOpen: this.onMenuOpen,
                refresh: this.refreshTab,
                branchAccount: {
                    add: this.addBranchAccount,
                    edit: this.editBranchAccount,
                    delete: this.deleteBranchAccount
                },
                bankAccount: {
                    add: this.addBankAccount,
                    edit: this.editBankAccount,
                    delete: this.deleteBankAccount
                },
                personal: {
                    add: this.addPersonToBranch,
                    addPerson: this.addPerson,
                    delete: this.deletePersonal
                },
                branchScheduler: {
                    change: this.changeBranchSchedulerSelectedDay

                },
                save: this.saveChanges
            }

            this.$scope.option = {
                maskOption: {
                    integerPositive: { decimals: 0, numberType: Components.NumberType.Integer, allowMinus: false },
                    decimal4Positive: { decimals: 4, numberType: Components.NumberType.Decimal, allowMinus: false },
                    decimal2Positive: { decimals: 2, numberType: Components.NumberType.Decimal, allowMinus: false },
                }
            }
            this.$scope.tabControl = {
                selectedTabPageIndex: 2,
                tabEvents: {
                    select: this.tabSelectChange,
                    deselect: this.deselectTab
                }
            };

            this.$scope.settings = {
                isTabEditable: false,
                branch: {},
                company: {},
                supplayerParams: {},
                customerParams: {},
                branchScheduler: {}
            };

            this.$scope.repositoriesDates = {
                customerCategoryData: null,
                customerTypeData: null,
                productTypeData: null
            }

            this.$scope.Components = {
                branchAccountParamGrid: this.initBranchAccountParamGrid(),
                bankAccountGrid: this.initBankAccountGrid(),
                branchPersonsGrid: this.initPersonalGrid(),
            }

            this.$scope.days = [
                { day: "monday" },
                { day: "tuesday" },
                { day: "wednesday" },
                { day: "thursday" },
                { day: "friday" },
                { day: "saturday" },
                { day: "sunday" }
            ];
        }

        changeBranchSchedulerSelectedDay = (index: number, value: boolean) => {
            if (value == true) {
                this.$scope.settings.branchScheduler.cash.ScheduleDays[index].Day = index;
            }
            else {
                if (!this.$scope.settings.branchScheduler.cash.ScheduleDays)
                    return;
                if (this.$scope.settings.branchScheduler.cash.ScheduleDays[index]) {
                    if (!this.$scope.settings.branchScheduler.cash.ScheduleDays[index].hasScheduler) {
                        delete this.$scope.settings.branchScheduler.cash.ScheduleDays[index];
                        this.$scope.settings.branchScheduler.cash.ScheduleDays = _.filter(this.$scope.settings.branchScheduler.cash.ScheduleDays, (item) => {
                            return !item || item.Day != index;
                        });
                    }
                    else
                        if (!this.$scope.settings.branchScheduler.cash.ScheduleDays[index].hasSScheduler) {
                            delete this.$scope.settings.branchScheduler.cash.ScheduleDays[index].SFrom;
                            delete this.$scope.settings.branchScheduler.cash.ScheduleDays[index].STo;
                        }
                }
            }

        }


        onMenuOpen = (tabIndex: number) => {
            if (!this.$scope.settings.isTabEditable) {
                $(".k-state-border-up").css("display", "block !important");
                return;
            }
            // 2: ფილიალის ანგარიშის პარამეტრები; 
            // 3: ფილიალის პერსონალი; 
            // 4: ბანკის ანგარიშები
            switch (tabIndex) {
                case 2: {
                    if (this.$scope.Components.branchAccountParamGrid.grid.select().length == 0) {
                        $("#branch-account-edit").css("opacity", "0.4");
                        $("#branch-account-delete").css("opacity", "0.4");
                    } else {
                        $("#branch-account-edit").css("opacity", "1");
                        $("#branch-account-delete").css("opacity", "1");
                    }
                }
                    break;
                case 3: {
                    if (this.$scope.Components.branchPersonsGrid.grid.select().length == 0) {
                        $("#branch-person-delete").css("opacity", "0.4");
                    } else {
                        $("#branch-person-delete").css("opacity", "1");
                    }
                }
                    break;
                case 4: {
                    if (this.$scope.Components.bankAccountGrid.grid.select().length == 0) {
                        $("#bank-account-edit").css("opacity", "0.4");
                        $("#bank-account-delete").css("opacity", "0.4");
                    } else {
                        $("#bank-account-edit").css("opacity", "1");
                        $("#bank-account-delete").css("opacity", "1");
                    }
                }
                    break;
            }
        }

        editableChanged = (editable: boolean) => {
            this.$scope.settings.isTabEditable = editable;
        }

        branchSelectedChanged = (branchId: number) => {
            if (branchId != this.$scope.settings.branch.selectedBranchId) {
                // this.deselectTab(this.$scope.tabControl.selectedTabIndex);
                this.saveChanges(this.$scope.tabControl.selectedTabIndex, this.$scope.settings.branch.selectedBranchId);

                this.$scope.settings.branch.selectedBranchId = branchId;
                this.getDataBySelectedTabPage(branchId);
            }
        }

        companySelectedChanged = (company: Apex.Crm.Interface.ICompany) => {
            if (company != this.$scope.settings.company.selectedCompany) {
                this.$scope.settings.company.selectedCompany = company;
                this.$scope.settings.branch.selectedBranchId = null;
                this.clearTabPagesData();
            }
        }



        tabSelectChange = (index: any): void => {
            this.$scope.tabControl.selectedTabIndex = index;
            if (index > 1) {
                this.$scope.tabControl.selectedTabPageIndex = index;
                this.getDataBySelectedTabPage(this.$scope.settings.branch.selectedBranchId);
            }
        }

        saveChanges = (oldIndex: number = null, branchId: number = null) => {

            if (oldIndex == null)
                oldIndex = this.$scope.tabControl.selectedTabIndex;
            if (branchId == null)
                branchId = this.$scope.settings.branch.selectedBranchId;


            if (!this.$scope.settings.isTabEditable)
                return;
            switch (oldIndex) {
                // მომწოდებლის პარამეტრები
                case 5: {
                    if (JSON.stringify(this.$scope.settings.supplayerParams.cash) != JSON.stringify(this.$scope.settings.supplayerParams.original))
                        if (this.$scope.settings.supplayerParams.cash != undefined && this.$scope.settings.supplayerParams.cash != null) {
                            this.$scope.settings.supplayerParams.cash.BranchId = branchId;
                            this.crmService.saveSupplierParam(this.$scope.settings.supplayerParams.cash).then(result => {
                                this.$scope.settings.supplayerParams.original = (<any>Object).assign(this.$scope.settings.supplayerParams.cash);
                                this.toaster.pop('success', 'ყურადღება', 'ცვლილებების შენახვა წარმატებით დასრულდა');
                            }).catch((error) => { this.toaster.pop('error', this.$translate.instant('Attention'), error.Message); });

                        }
                }
                    break;
                // მყიდველის პარამეტრები
                case 6: {
                    if (JSON.stringify(this.$scope.settings.customerParams.cash) != JSON.stringify(this.$scope.settings.customerParams.original)) {
                        if (this.$scope.settings.customerParams.cash != undefined && this.$scope.settings.customerParams.cash != null) {
                            this.$scope.settings.customerParams.cash.BranchId = branchId;
                            this.crmService.saveCustomerParam(this.$scope.settings.customerParams.cash).then(result => {
                                this.$scope.settings.customerParams.original = (<any>Object).assign(this.$scope.settings.customerParams.cash);
                                this.toaster.pop('success', 'ყურადღება', 'ცვლილებების შენახვა წარმატებით დასრულდა');
                            }).catch(error => { this.toaster.pop('error', this.$translate.instant('Attention'), error.Message); });

                        }
                    }

                }
                    break;
                // მუშაობის განრიგი
                case 7: {
                    if (JSON.stringify(this.$scope.settings.branchScheduler.cash.ScheduleDays) != JSON.stringify(this.$scope.settings.branchScheduler.original.ScheduleDays)) {
                        if (this.$scope.settings.branchScheduler.cash != undefined && this.$scope.settings.branchScheduler.cash != null) {
                            this.$scope.settings.branchScheduler.cash.BranchId = branchId;
                        }



                        this.crmService.saveBranchSchedule(this.$scope.settings.branchScheduler.cash).then(result => {
                            this.$scope.settings.branchScheduler.original = jQuery.extend(true, {}, this.$scope.settings.branchScheduler.cash);
                            this.toaster.pop('success', 'ყურადღება', 'ცვლილებების შენახვა წარმატებით დასრულდა');
                        }).catch(error => { this.toaster.pop('error', this.$translate.instant('Attention'), error.Message); });

                    }
                } break;
            }
        }

        deselectTab = (oldIndex) => {
            if (!this.$scope.settings.isTabEditable)
                return;

            switch (oldIndex) {
                // მომწოდებლის პარამეტრები
                case 5: {
                    if (JSON.stringify(this.$scope.settings.supplayerParams.cash) != JSON.stringify(this.$scope.settings.supplayerParams.original))
                        if (this.$scope.settings.supplayerParams.cash != undefined && this.$scope.settings.supplayerParams.cash != null) {
                            Apex.Core.Utils.ModalForm.openSaveConfimation((yes) => {
                                this.saveChanges(oldIndex, this.$scope.settings.branch.selectedBranchId);
                            });
                        }
                }
                    break;
                // მყიდველის პარამეტრები
                case 6: {
                    if (JSON.stringify(this.$scope.settings.customerParams.cash) != JSON.stringify(this.$scope.settings.customerParams.original)) {
                        if (this.$scope.settings.customerParams.cash != undefined && this.$scope.settings.customerParams.cash != null) {
                            this.$scope.settings.customerParams.cash.BranchId = this.$scope.settings.branch.selectedBranchId;
                            Apex.Core.Utils.ModalForm.openSaveConfimation((yes) => {
                                this.saveChanges(oldIndex, this.$scope.settings.branch.selectedBranchId);
                            });
                        }
                    }

                }
                    break;
                // მუშაობის განრიგი
                case 7: {
                            
                    if (JSON.stringify(this.$scope.settings.branchScheduler.cash.ScheduleDays) != JSON.stringify(this.$scope.settings.branchScheduler.original.ScheduleDays)) {
            

                        if (this.$scope.settings.branchScheduler.cash != undefined && this.$scope.settings.branchScheduler.cash != null) {
               
                            this.$scope.settings.branchScheduler.cash.BranchId = this.$scope.settings.branch.selectedBranchId;
                        }
                        Apex.Core.Utils.ModalForm.openSaveConfimation((yes) => {
                            this.saveChanges(oldIndex, this.$scope.settings.branch.selectedBranchId);
                        });
                    }
                } break;
            }

            // this.saveChanges(oldIndex, this.$scope.settings.branch.selectedBranchId);
        }
        getDataBySelectedTabPage = (selectedBranchId: number) => {
            // Core.Utils.ModalForm.openDeleteConfirmations(() => {                   
            // });

            if (selectedBranchId === null || selectedBranchId == undefined) {
                this.clearTabPagesData();
                return;
            }
            switch (this.$scope.tabControl.selectedTabPageIndex) {
                // ფილიალის ანგარიშის პარამეტრები
                case 2: {
                    this.$scope.Components.branchAccountParamGrid.gridView.showLoading();
                    this.crmService.getCompanyBranchAccountViewByBranchId(selectedBranchId).then(result => {
                        this.$scope.Components.branchAccountParamGrid.dataSource.data(result.Result);
                        this.$scope.Components.branchAccountParamGrid.gridView.hideLoading();
                    }).catch(error => {
                        this.$scope.Components.branchAccountParamGrid.gridView.hideLoading();
                        this.toaster.pop(error)
                    });

                } break;
                // ფილიალის პერსონალი
                case 3: {
                    this.$scope.Components.branchPersonsGrid.gridView.showLoading();
                    this.crmService.getBranchPersons(null, selectedBranchId, null, true).then(result => {
                        this.$scope.Components.branchPersonsGrid.dataSource.data(result.Result);
                        this.$scope.Components.branchPersonsGrid.gridView.hideLoading();
                    });

                } break;
                // ბანკის ანგარიშები
                case 4: {
                    this.$scope.Components.bankAccountGrid.gridView.showLoading();
                    this.crmService.getCompanyBankAccountView(selectedBranchId).then(result => {
                        this.$scope.Components.bankAccountGrid.dataSource.data(result.Result);
                        this.$scope.Components.bankAccountGrid.gridView.hideLoading();
                    }).catch(Error => {
                        this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG'));
                        this.$scope.Components.bankAccountGrid.gridView.hideLoading();
                    });

                }
                    break;
                // მომწოდებლის პარამეტრები
                case 5: {
                    $("#prod-supp-days").focus();
                    this.crmService.getSupplierParams(selectedBranchId).then(result => {
                        this.$scope.settings.supplayerParams.cash = (<any>Object).assign({}, result.Result);
                        this.$scope.settings.supplayerParams.original = (<any>Object).assign({}, result.Result);
                    }).catch(Error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));
                }
                    break;
                // მყიდველის პარამეტრები ///???წასაშლელია
                case 6: {
                    // if (!this.$scope.repositoriesDates.customerCategoryData) {
                    //     this.commonService.getDTAny("AB4F6F14-CE84-48A4-BF4E-E1B697D03302").then(result => {
                    //         this.$scope.repositoriesDates.customerCategoryData = result.Result;
                    //     }).catch(error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));
                    // }
                    // if (!this.$scope.repositoriesDates.customerTypeData) {
                    //     this.commonService.getDTAny("BA6B4A21-5E74-4EF4-BACA-CAB3B4444DB6").then(result => {
                    //         this.$scope.repositoriesDates.customerTypeData = result.Result;
                    //     }).catch(error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));

                    // }
                    // if (!this.$scope.repositoriesDates.productTypeData) {
                    //     this.commonService.getDTAny("A9F55A72-E929-477A-A6FB-EBC958C69E97").then(result => {
                    //         this.$scope.repositoriesDates.productTypeData = result.Result;
                    //     }).catch(error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));
                    // }
                    this.crmService.getCustomerParams(selectedBranchId).then(result => {
                        this.$scope.settings.customerParams.cash = (<any>Object).assign({}, result.Result);
                        this.$scope.settings.customerParams.original = (<any>Object).assign({}, result.Result);
                    }).catch(Error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));
                }
                    break;
                // მუშაობის განრიგი
                case 7: {
                    this.crmService.getBranchScheduler(selectedBranchId).then(result => {
                        this.convertToValidDate(result.Result);
                        var orderedList: Interface.IBranchSchedule;
                        if (result.Result) {
                            orderedList = <Interface.IBranchSchedule>{
                                BranchId: result.Result.BranchId,
                                ScheduleDays: <Interface.IScheduleDay[]>[]
                            }
                            for (var i = 0; i < 7; i++) {
                                if ((<Interface.IBranchSchedule>result.Result).ScheduleDays[i]) {
                                    orderedList.ScheduleDays[(<Interface.IBranchSchedule>result.Result).ScheduleDays[i].Day] = (<Interface.IBranchSchedule>result.Result).ScheduleDays[i];
                                }
                            }
                        }

                        this.$scope.settings.branchScheduler.cash = jQuery.extend(true, {}, orderedList); // (<any>Object).assign({}, result.Result);
                        this.$scope.settings.branchScheduler.original = jQuery.extend(true, {}, orderedList); //(<any>Object).assign({}, result.Result);
                    }).catch(error => this.toaster.pop('error', this.$translate.instant('Attention'), this.$translate.instant('DIALOGS_ERROR_MSG')));
                } break;
            }
        }


        clearTabPagesData = () => {
            this.$scope.Components.branchAccountParamGrid.dataSource.data([]);
            this.$scope.Components.bankAccountGrid.dataSource.data([]);
            this.$scope.Components.branchPersonsGrid.dataSource.data([]);
            this.$scope.settings.supplayerParams.cash = null;
            this.$scope.settings.customerParams.cash = null
            this.$scope.settings.branchScheduler.cash = null;
        }

        convertToValidDate = (serviceStackDate: any) => {
            if (!serviceStackDate)
                return;

            if (serviceStackDate.ScheduleDays !== undefined) {
                var hour: string;
                var minute: string;

                for (var item = 0; item < serviceStackDate.ScheduleDays.length; item++) {
                    if (!!serviceStackDate.ScheduleDays[item]) {
                        if (serviceStackDate.ScheduleDays[item].From) {
                            serviceStackDate.ScheduleDays[item].hasScheduler = serviceStackDate.ScheduleDays[item].From !== "undefined";
                            serviceStackDate.ScheduleDays[item].From = new Date(parseInt(serviceStackDate.ScheduleDays[item].From.substr(6)));
                        }
                        if (serviceStackDate.ScheduleDays[item].To) {
                            serviceStackDate.ScheduleDays[item].To = new Date(parseInt(serviceStackDate.ScheduleDays[item].To.substr(6)));
                        }
                        if (serviceStackDate.ScheduleDays[item].SFrom) {
                            serviceStackDate.ScheduleDays[item].hasSScheduler = serviceStackDate.ScheduleDays[item].SFrom !== "undefined";
                            serviceStackDate.ScheduleDays[item].SFrom = new Date(parseInt(serviceStackDate.ScheduleDays[item].SFrom.substr(6)));
                        }
                        if (serviceStackDate.ScheduleDays[item].STo) {
                            serviceStackDate.ScheduleDays[item].STo = new Date(parseInt(serviceStackDate.ScheduleDays[item].STo.substr(6)));
                        }

                    }
                }
            }
        }
        initPersonalGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.branchPersonsDataSource(),
                    columns: this.PersonalGridColumns,
                    settings: {
                        editble: { disableEditRow: true }
                    },
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: () => {
                            }
                        }
                    ]

                };

            return grid;
        }
        branchPersonsDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        fields: {
                            PersonSN: { type: "string" },
                            FirstName: { type: "string" },
                            LastName: { type: "string" }
                        }
                    }
                }
            });
        }

        initBankAccountGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initBankAccountDataSource(),
                columns: this.bankAccountGridColumns,
                settings: { editble: { disableEditRow: true } }
            }
            return grid;
        }

        initBankAccountDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        fields: {
                            Account: { type: "string" },
                            Note: { type: "string" },
                            BankName: { type: "string" }
                        }
                    }
                }
            });
        }


        /// events
        refreshTab = () => {
            this.getDataBySelectedTabPage(this.$scope.settings.branch.selectedBranchId);
        }
        addPersonToBranch = () => {
            var dataSource = this.$scope.Components.branchPersonsGrid.dataSource.data();
            var data_items: Array<number> = new Array<number>();
            if (dataSource != null && dataSource != undefined && dataSource.length > 0) {
                for (var i = 0; i < dataSource.length; i++) {
                    data_items.push(dataSource[i].Person.Id);
                }
            }
            this.openPersonsModalForm({
                data: data_items,
                branchId: this.$scope.settings.branch.selectedBranchId
            });
        }
        addPerson = () => {
            this.openAddPersonModalForm(null);
        }
        deletePersonal = () => {
            var branchPerson = this.$scope.Components.branchPersonsGrid.gridView.selectedItem();
            if (branchPerson != undefined && branchPerson != null) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.crmService.deleteBranchPersons(branchPerson.Id).then((result) => {
                        this.$scope.Components.branchPersonsGrid.dataSource.remove(branchPerson);
                    });
                });
            }
        }
        addBankAccount = () => {
            this.openBankAccountModalForm({
                branchId: this.$scope.settings.branch.selectedBranchId,
                BankAccount: null
            })
        }
        editBankAccount = () => {
            var selected_item = this.$scope.Components.bankAccountGrid.gridView.selectedItem();
            if (selected_item != undefined && selected_item != null)
                this.openBankAccountModalForm({
                    branchId: this.$scope.settings.branch.selectedBranchId,
                    BankAccount: <Apex.Crm.Interface.ICompanyBankAccountView>selected_item
                })
        }
        deleteBankAccount = () => {
            var bank_account = this.$scope.Components.bankAccountGrid.gridView.selectedItem();
            if (bank_account != undefined && bank_account != null) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.crmService.deleteCompanyBankAccount(bank_account.Id).then((result) => {
                        this.$scope.Components.bankAccountGrid.dataSource.remove(bank_account);
                    })
                });
            }
        }

        addBranchAccount = () => {
            this.openBranchAccountModalForm({
                branchId: this.$scope.settings.branch.selectedBranchId,
                branchAccount: null
            });
        }
        editBranchAccount = () => {
            var selected_item = this.$scope.Components.branchAccountParamGrid.gridView.selectedItem();
            if (selected_item != undefined && selected_item != null)
                this.openBranchAccountModalForm({
                    branchId: this.$scope.settings.branch.selectedBranchId,
                    branchAccount: this.$scope.Components.branchAccountParamGrid.gridView.selectedItem()
                });
        }
        deleteBranchAccount = () => {
            var branch_account = this.$scope.Components.branchAccountParamGrid.gridView.selectedItem();
            if (branch_account != undefined && branch_account != null) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.crmService.deleteCompanyBranchAccount((<Apex.Crm.Interface.ICompanyBranchAccount>branch_account).Id).then(result => {
                        this.$scope.Components.branchAccountParamGrid.dataSource.remove(branch_account);
                    });
                });
            }
        }
        /// ***************
        openAddPersonModalForm = (data: any) => {

            Core.Utils.ModalForm.openModalForm('personal.modal.html', PersonalModal.getName(), data, Core.Utils.ModalSize.Large).result.then((result) => {
                if (typeof result != undefined && result != false) {
                    this.crmService.saveBranchPersons({
                        BranchId: this.$scope.settings.branch.selectedBranchId,
                        Person: {
                            Id: result
                        }
                    }).then(result => {
                        this.refreshTab();
                    });
                }


            });
        }
        openPersonsModalForm = (data: any) => {

            Core.Utils.ModalForm.openModalForm('crm.personal.html', PersonsModal.getName(), data, Core.Utils.ModalSize.Medium).result.then((result) => {
                if (typeof result !== "undefined" && result) {
                    this.$timeout(() => {
                        this.refreshTab();
                    });

                }
            });
        }

        openBankAccountModalForm = (data: any) => {
            Core.Utils.ModalForm.openModalForm('crm.bank.account.html', BankAccountModal.getName(), data, Core.Utils.ModalSize.Small).result.then((result) => {
                if (typeof result !== "undefined" && result) {
                    this.getDataBySelectedTabPage(this.$scope.settings.branch.selectedBranchId);
                }
            });
        }
        openBranchAccountModalForm = (data: any) => {
            Core.Utils.ModalForm.openModalForm('crm.branch.account.html', BranchAccountModal.getName(), data, Core.Utils.ModalSize.Small).result.then((result) => {
                if (typeof result !== "undefined" && result) {
                    this.getDataBySelectedTabPage(this.$scope.settings.branch.selectedBranchId);
                }
            });
        }

        bankAccountGridColumns: Array<Apex.Core.Interfaces.IGridColumn> = [
            { field: "Account", titleTranslated: "acc" },
            { field: "Note", titleTranslated: "note" },
            { field: "BankName", titleTranslated: "bank" }
        ]

        initBranchAccountParamGrid = () => {
            var grid: Apex.Components.IApxGrid = {
                dataSource: this.initBranchAccountParamDataSource(),
                columns: this.branchAccountGridColumns,
                settings: { editble: { disableEditRow: true } }
            }
            return grid;
        }
        initBranchAccountParamDataSource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                data: [],
                schema: {
                    model: {
                        fields: {
                            Account: { type: "string" },
                            AccountTypeName: { type: "string" }
                        }
                    }
                }
            });
        }
        branchAccountGridColumns: Array<Apex.Core.Interfaces.IGridColumn> = [
            { field: "Account", titleTranslated: "acc" },
            { field: "AccountTypeName", titleTranslated: "acc_type" }
        ]



        PersonalGridColumns: Array<Apex.Core.Interfaces.IGridColumn> =
        [
            {
                field: "PersonSN", titleTranslated: "personalNumber", width: 90, template: '<span>{{dataItem.Person.PersonSN}}</span>'
            },
            {
                field: "FirstName", titleTranslated: "name", width: 100, template: '<span>{{dataItem.Person.FirstName}}</span>'
            },
            {
                field: "LastName", titleTranslated: "lastname", width: 100, template: '<span>{{dataItem.Person.LastName}}</span>'
            }
            // {
            //     field: "PName", titleTranslated: "PName", width: 150
            // },
            // {
            //     field: "DateBorn", titleTranslated: "Bdate", format: Core.Kendo.Format.Date, width: 100
            // },
            // {
            //     field: "Position", titleTranslated: "Position", width: 150
            // },
            // {
            //     field: "Mobile", titleTranslated: "mobileN", width: 100
            // },
            // {
            //     field: "Description", titleTranslated: "Description", width: 170
            // },
            // {
            //     field: "Spend", titleTranslated: "Spend", width: 90, template:
            //     `<div class="text-center"  ng-if="dataItem.Spend"><i class="fa fa-check text-success" ></i></div>`
            // } 
        ];

    }
}