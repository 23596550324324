/// <reference path='../_reference.ts' />
/// <reference path='../../../Apex.WebUI.Core/app/Interfaces/IServiceInterface.ts' />

module Apex.Crm.Services {
    'use strict'
    export class Crm extends Core.Service {
        public OrdersQueryUrl = "/Crm/Orders/View/Query";
        public crmProductListQueryUrl: string = '/Crm/Orders/Avaliable/Products/Query';
        public branchAccountQueryUrl: string = "/Crm/Accounts/Query";

        getCrmPrOrderStatuses():Promise<Interface.GetCrmPrOrderStatusResponse>{
            return this.httpGet("/Crm/Orders/PrOrderStatus?&jsconfig=inv:true");
        }

        saveBranchSchedule(branch_schedule: Apex.Crm.Interface.IBranchSchedule): angular.IPromise<any> {
            return this.httpPut("/Crm/Company/Branch/Schedule", { BranchSchedule: branch_schedule });
        }
        saveCustomerParam(custom_param: Apex.Crm.Interface.ICustomerParams): angular.IPromise<any> {
            return this.httpPut("/Crm/Company/Branch/CustomerParams", { CustomerParams: custom_param });
        }
        saveSupplierParam(supp_param: Apex.Crm.Interface.ISupplierParams): angular.IPromise<any> {
            return this.httpPut("/Crm/Company/Branch/SupplierParams", { Item: supp_param });

        }
        deleteBranchPersons(branch_person_id: number): angular.IPromise<any> {
            return this.httpDelete("/Crm/Persons/Branch/" + branch_person_id);
        }

        saveBranchPersons(branchPerson: Crm.Interface.IBranchPerson): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons/Branch/", { Item: branchPerson });
        }
        getBranchPersons(id?: number, branchId?: number, personId?: number, withPersonData?: boolean): angular.IPromise<Interface.GetBranchPersonsResponse> {
            var params = {
                nm: 'Id={0}&BranchId={1}&PersonId={2}&WithPersonData={3}',
                pr: [id, branchId, personId, withPersonData]
            };
            return this.httpGet("/Crm/company/Branch/Persons/", params);
        }

        deleteBranch(branchId: number): any {
            return this.httpDelete("/Crm/Company/Branch/" + branchId);
        }
        deleteBranchAsync(branchId: number): angular.IPromise<any> {
            return this.deleteBranch(branchId);

        }
        saveBranchesAsync(branches: Array<Apex.Crm.Interface.ICompanyBranch>): angular.IPromise<any> {
            return this.saveBranches(branches);
        }
        saveBranches(branches: Array<Apex.Crm.Interface.ICompanyBranch>): any {
            return this.httpPut("/Crm/Company/Branch", { Branch: branches });
        }


        saveCompanyAsync(company: Apex.Crm.Interface.ICompany): angular.IPromise<any> {
            return this.saveCompany(company);
        }

        saveCompany(company: Apex.Crm.Interface.ICompany): any {
            return this.httpPut("/Crm/Company", { CrmCompany: company });
        }

        saveBankAccount(bankAccount: Apex.Crm.Interface.ICompanyBankAccount): angular.IPromise<any> {
            return this.httpPut("/Crm/CompanyBankAccount", { Account: bankAccount });
        }

        ///Common
        getCompanyBankAccounts(bank_id?: string): angular.IPromise<any> {
            if (bank_id != undefined && bank_id != null)
                return this.httpGet("/Common/Bank/" + bank_id);
            else
                return this.httpGet("/Common/Bank");
        }
        ///*****

        deleteCompanyBankAccount(accountId: string): angular.IPromise<any> {
            return this.httpDelete("/Crm/Company/Branch/BankAccount/" + accountId);
        }
        deleteCompanyBranchAccount(accountId: any): angular.IPromise<any> {
            return this.httpDelete("/Crm/Accounts/BranchAccounts/Account/" + accountId);
        }

        saveCompanyBranchAccount(companyBranchAccount: Apex.Crm.Interface.ICompanyBranchAccount): angular.IPromise<any> {
            return this.httpPut("/Crm/Accounts/BranchAccounts/Account", { Account: companyBranchAccount });
        }
        // /RS/CompanyInfo
        getRSCompanyInfo(SN: string): angular.IPromise<any> {
            var params = {
                nm: 'SN={0}',
                pr: [SN]
            };
            return this.httpGet("/RS/CompanyInfo", params);
        }

        getCompanyBranches(companyIds?: number, isOwn?: boolean, isPartner?: boolean, isSupplier?: boolean, isCustomer?: boolean): angular.IPromise<any> {
            var params = {
                nm: 'CompanyId={0}&isOwn={1}&isPartner={2}&isSupplier={3}&isCustomer={4}',
                pr: [companyIds, isOwn, isPartner, isSupplier, isCustomer]
            };

            return this.httpGet("/Crm/Company/Branches", params);
        }
        getCrmOrderDetails(orderId: number,
            subOrderId: number,
            includeProducts: boolean,
            includeServices: boolean,
            includePayments: boolean,
            includeDocuments: boolean,
            includeProductWhs: boolean,
            includeProductWhsBranches: boolean
        ): angular.IPromise<any> {
            var params = {
                nm: 'Id={0}&SubOrderId={1}&IncludeProducts={2}&IncludeServices={3}&IncludePayments={4}&IncludeDocuments={5}&IncludeProductWhs={6}&IncludeProductWhsBranches={7}&jsconfig=dh:iso8601',
                pr: [orderId, subOrderId, includeProducts, includeServices, includePayments, includeDocuments, includeProductWhs, includeProductWhsBranches]
            };
            return this.httpGet('/Crm/Orders/Details', params)
        }

        getBranchScheduler(branch_id: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Company/Branch/" + branch_id + "/Schedule");
        }

        getCustomerParams(branch_id: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Company/Branch/" + branch_id + "/CustomerParams");
        }

        checkCrmOrderProdRem(orderId: any, prodId: string, quantity: number): angular.IPromise<any> {
            return this.httpGet('/Crm/Orders/Avaliable/Products/Allowed?OrderId=' + orderId + '&ProdId=' + prodId + '&OCount=' + quantity);
        }


        getSupplierParams(branch_id: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Company/Branch/" + branch_id + "/SupplierParams");
        }

        getCompanyBankAccountView(branch_id: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Company/Branch/" + branch_id + "/BankAccountView");
        }

        getCompanyBranchAccountViewByBranchId(branch_id: number): angular.IPromise<any> {
            return this.httpGet("/Crm/Accounts/BranchAccounts/AccountView/?BranchId=" + branch_id);
        }

        getCompanyBranchesByStatus(status: CompanyBranchStatus): angular.IPromise<{ Result: Array<Crm.Interface.ICompanyBranch> }> {
            return this.httpGetCached("/Crm/Company/Branch/GetCompanyBranchesByStatus/" + status);
        }

        GetContractDetailsByBranch(supplierBranchId: any, recipientBranchId: any): angular.IPromise<any> {
            return this.httpGet("/Crm/Contracts/GefContractDetailsByBranch/?OwnBrId=" + supplierBranchId + "&ClientBrId=" + recipientBranchId);
        }

        deleteCrmOrder(Id: any): angular.IPromise<any> {
            return this.httpDelete('/Crm/Orders/' + Id);
        }

        changeCrmOrderStatus(orderId: any, status: any): angular.IPromise<any> {
            var params = { Status: status };
            return this.httpPatch('/Crm/Orders/' + orderId + '/Status', params);
        }

        deleteCrmOrderPayment(paymentId: any): angular.IPromise<any> {
            return this.httpDelete('/Crm/Orders/Payments/' + paymentId);
        }

        getCrmOrderPrepareList(): angular.IPromise<any> {
            return this.httpGet("/Crm/Orders/PrepareLists");
        }

        getDeliveryTransport(presalerId: number, branchId: number): angular.IPromise<any> {
            return this.httpGet('/Crm/Orders/Suggested/Delivery/Transport?BranchId=' + branchId + '&PresalerId=' + presalerId);
        }

        getBranchCrmOrderStats(branchId: number): angular.IPromise<any> {
            return this.httpGet('/Crm/Company/Branch/' + branchId + '/Orders/Stats');
        }

        saveCrmOrder(order: any): angular.IPromise<any> {
            return this.httpPost("/Crm/Orders", { NewOrder: order });
        }

        getCrmOrder(orderId: any): angular.IPromise<any> {
            return this.httpGet("/Crm/Orders/" + orderId + '?IncludeRecipientStats=true');
        }

        getCompanies(isOwn, isPartner, isSupplier, isCustomer, rsCode = null): angular.IPromise<any> {
            var params = {
                nm: 'isOwn={0}&isPartner={1}&isSupplier={2}&isCustomer={3}&RsCode={4}',
                pr: [isOwn, isPartner, isSupplier, isCustomer, rsCode]
            };
            return this.httpGet("/Crm/CompanyView", params);
        }
        getCompany(id: number): angular.IPromise<Apex.Crm.Interface.ICompany> {
            return this.httpGet("/Crm/CompanyView/" + id);
        }
        getCompanyBranch(Id?: number, CompanyId?: number): angular.IPromise<any> {
            if (Id != null)
                return this.httpGet("/Crm/Company/Branch/" + Id+"?jsconfig=inv:true");
            else if (CompanyId != null)
                return this.httpGet("/Crm/Company/" + CompanyId + "/Branch?jsconfig=inv:true");
            else if (Id == null && CompanyId == null)
                return this.httpGet("/Crm/Company/Branch?jsconfig=inv:true");
        }
        deleteCompany(id: number): angular.IPromise<any> {
            return this.httpDelete("/Crm/Company/" + id);
        }
        
        getPersons(): angular.IPromise<any> {
            return this.httpGet("/Crm/Persons");
        };
        SavePersons(Person: Apex.Crm.Interface.IPerson): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons", { Person: Person });
        };
        deletePerson(Unids: any): angular.IPromise<any> {
            return this.httpDelete('/Crm/Persons/' + Unids);
        }

        getCities(id: string = null): angular.IPromise<any> {
            if (id == null)
                return this.httpGet("/Common/City");
            return this.httpGet("/Common/City/" + id);
        }

        // Personal Phone //
        getPersonsPhone(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Crm/Persons/' + Unid + '/Phones');
        };
        SavePersonsPhones(Phones: Apex.Crm.Interface.IPhones): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons/Phones", { Phone: Phones });
        };
        deletePersonalPhones(phoneIds: Array<number>): angular.IPromise<any> {
            return this.httpDelete('/Crm/Persons/Phone/[' + phoneIds + ']');
        }

        getDtAny(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Common/Dictionary/DTAny/' + Unid);
        };

        // Personal Email // 
        getPersonsEmail(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Crm/Persons/' + Unid + '/EMails');
        };
        SavePersonsEmail(Emails: Apex.Crm.Interface.IEmail): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons/EMails", { Email: Emails });
        };
        deletePersonalEmails(emailIs: Array<number>): angular.IPromise<any> {
            return this.httpDelete('/Crm/Persons/EMail/[' + emailIs + ']');
        }
        // Personal WebPage //
        getPersonsWebPage(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Crm/Persons/' + Unid + '/WebPage');
        };
        SavePersonsWeb(WebPage: Apex.Crm.Interface.IWebPage): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons/WebPage", { WebPage: WebPage });
        };
        deletePersonalWebPages(webIds: Array<number>): angular.IPromise<any> {
            return this.httpDelete('/Crm/Persons/WebPage/[' + webIds + ']');
        }
        //Personal Adress //
        saveAddressAsync(companyAdress: Apex.Crm.Interface.IAddress): angular.IPromise<any> {
            return this.saveAddress(companyAdress);
        };
        saveAddress(companyAdress: Apex.Crm.Interface.IAddress): any {
            return this.httpPut("/Crm/Address/", { CompanyAddress: companyAdress });
        };
        getPersonsAdress(Unid: string): angular.IPromise<any> {
            return this.httpGet('/Crm/Persons/Address/' + Unid);
        };
        SavePersonsAdress(Adress: Apex.Crm.Interface.IPersonsAddress): angular.IPromise<any> {
            return this.httpPut("/Crm/Persons/Address/", { Address: Adress });
        };

        deletePersonalAddress(addressIds: Array<number>): angular.IPromise<any> {
            return this.httpDelete('/Crm/Persons/Address/[' + addressIds + ']');
        }



        getBranchesExp(status: CompanyBranchStatus, withBankAccounts: boolean = false, withBranchAccounts: boolean = false, onlyHeadBranches: boolean = false): angular.IPromise<{ Result: Array<Crm.Interface.ICompanyBranch> }> {
            var par = {
                nm: "WithBankAccounts={0}&WithBranchAccounts={1}&OnlyHeadBranches={2}",
                pr: [withBankAccounts, withBranchAccounts, onlyHeadBranches]
            }
            return this.httpGetCached("/Crm/Company/Branch/GetCompanyBranchesByStatus/" + status, par);
        }

        getAccountsByOperID(operID): angular.IPromise<any> {
            return this.httpGet('/Accounting/Oper/' + operID + '/Template/Accounts/');
        }
        cardAccountsQueryUrl = '/Crm/Accounts/Query';
        getBanks(): angular.IPromise<any> {
            return this.httpGet('/Common/Bank');
        }
        getDepartments(): angular.IPromise<any> {
            return this.httpGet('/AGD/Departaments');
        };
        getPost(): angular.IPromise<any> {
            return this.httpGet('/AGD/Post');
        }
        SaveStaffParams(StaffParams: Apex.Crm.Interface.iStaffParams): angular.IPromise<any> {
            return this.httpPost("/Crm/StaffParams", { StaffParams: StaffParams });
        };

        getFixValChildren(Mgrid: string): angular.IPromise<any> {
            return this.httpGet('/Core/FixVal/ChildHierarchy?Mgrid=' + Mgrid);
        };
        GetStaffParams(id: number): angular.IPromise<any> {
            return this.httpGet('/Crm/StaffParams/' + id);
        }
    }

    export enum CompanyBranchStatus {
        Own = 1,
        Customer = 2,
        OwnOrSupplier = 3,
        Supplier = 4
    }

    export enum AccountType {
        None = 0,
        Cash = 1,
        Bank = 2,
        Warehouse = 10,
        Supplier = 12, //Creditors
        Client = 13 // Debitors

    }
}