/// <reference path='../_reference.ts' />

module Apex.Crm.Controllers {
    export interface AddressScope extends angular.IScope {
        Address: Apex.Crm.Interface.IAddress;
        repositories: {
            cities: Array<Apex.Crm.Interface.ICity>
            region: Array<any>;
        }
        events: {
            openMapForm: Function,
            getCities:Function,
            getRegions:Function
        }
        myStyle: any;
    }
    export class AddressController extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, Globals.translate, 'crmService', 'commonService', '$timeout'];
        constructor(private $scope: AddressScope, private toaster: any, private $translate, private crmService: Apex.Crm.Services.Crm,
            private commonService: Apex.Operations.Services.Common, private $timeout) {
            super(arguments, CompanyController.$inject);
            if ($scope.Address == undefined || $scope.Address == null)
                $scope.Address = {};
            this.initialisation();

            $timeout(()=> {
                // this.initRepositories();    
            });
            
        }

        initialisation = () => {
            this.$scope.myStyle = this.styeleExpresion();
            this.$scope.events = {
                openMapForm: this.openMapForm,
                getCities:this.getCities,
                getRegions:this.getRegion
            }
            this.$scope.repositories = {
                cities: new Array<Apex.Crm.Interface.ICity>(),
                region: new Array<any>()
            }
        }

        styeleExpresion = (): Object => {
            if (this.$scope.Address.Location != undefined && this.$scope.Address.Location.Latitude != undefined && this.$scope.Address.Location.Longitude != undefined) {
                return {
                    "color": "#2196f3",
                    "font-size": "25px"
                }
            }
            else return {
                "color": "red",
                "font-size": "25px"
            }
        }
        openMapForm = () => {
            if (this.$scope.Address == undefined)
                this.$scope.Address = <Apex.Crm.Interface.IAddress>{};
            // leaflet map ის მოდალური ფორმის გამოძახება
            var dlg = Core.Utils.ModalForm.openModalForm("leaflet.modal.html", LeafLetModal.getName(), this.$scope.Address, Core.Utils.ModalSize.Medium);
            // მოდალური ფორმა result- ში აბრუნებს marker ობიექტს {layer: string,lat: number,lng:number}
            dlg.result.then(result => {
                if (typeof result != undefined && result != false && result != null) {
                    this.$scope.Address.Location = <Apex.Crm.Interface.IGPSLocation>{
                        Latitude: result[0].lat,
                        Longitude: result[0].lng
                    };
                    this.$scope.myStyle = this.styeleExpresion();
                }
            });
        }

        getCities=(id:string=null)=>{
            return this.crmService.getCities(id);
        }
        getRegion=()=>{
            return this.crmService.getDtAny("e13b9cb4-9d87-4912-976a-18445b62e40c")
        }
    }
}