/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface CompanyEditScope extends angular.IScope {
        formName: string;
        model: {
            company?: Apex.Crm.Interface.ICompany
            companyOrig?: Apex.Crm.Interface.ICompany;
            branch?: {
                firstMaxId?: number,
                maxId?: number,
                dataSource?: kendo.data.TreeListDataSource,
                selectedBranch?: any,
                forSaveBranches?: Array<Apex.Crm.Interface.ICompanyBranch>,
                forDeleteBranches?: Array<Apex.Crm.Interface.ICompanyBranch>
            }
            vatTypes?: Array<any>;
        }
        Components: {
            branchTreeList: Apex.Components.IApxTreeList
        }
        events: {
            closeForm: Function,
            save?: Function,
            tabChanged?: Function,
            branchEvents: {
                refresh?: Function,
                expand?: Function,
                collapse?: Function,
                editBranch?: Function,
                addBranch?: Function,
                addBranchUnder?: Function,
                delete: Function
            },
            nameTextBoxChange?: Function,
            searchBtnClick?: Function

        }
        settings?: {
            tabIsEditable: boolean,
            saveTabChanges?: any,
            disablePopUpBtn: boolean
        };
        isSaved?: boolean;
    }

    export class CompanyModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster, "$timeout"];

        /** Constructo and initialization */
        constructor(private $scope: CompanyEditScope, private $translate, data: Apex.Crm.Interface.ICompany, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, PersonalModal.$inject);

            this.initialisation();
            $scope.$watch("Components.branchTreeList", (scop) => {
                $("#rs_code").keyup((e) => {
                    if (e.keyCode == 13) {
                        this.searchBtnClick();
                    }
                });
                $("#context-menu").kendoContextMenu({
                    target: "#branch",
                    open: (e) => {
                        var data = (this.$scope.Components.branchTreeList.treeList.select()).data();
                        var dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();

                        if (!data) {
                            this.$scope.settings.disablePopUpBtn=true;
                            if (!dataSource || dataSource.length == 0) {
                                $("#modal-refresh-popup").css("opacity", "0.4");
                                $("#modal-expand-popup").css("opacity", "0.4");
                                $("#modal-collapse-popup").css("opacity", "0.4");
                            }

                            $("#modal-add-popup").css("opacity", "0.4");
                            $("#modal-edit-popup").css("opacity", "0.4");
                            $("#edit-branch-modal").css("opacity", "0.4");
                            $("#delete-branch-modal").css("opacity", "0.4");
                        }
                        else {
                            this.$scope.settings.disablePopUpBtn=false;
                            $("#modal-expand-popup").css("opacity", "1");
                            $("#modal-collapse-popup").css("opacity", "1");
                            $("#modal-refresh-popup").css("opacity", "1");
                            $("#modal-add-popup").css("opacity", "1");
                            $("#modal-edit-popup").css("opacity", "1");
                            $("#edit-branch-modal").css("opacity", "1");
                            $("#delete-branch-modal").css("opacity", "1");
                        }
                    }
                });
            });

            if (data != undefined && data != null) {
                $scope.formName = "რედაქტირება";
                $scope.model.company = jQuery.extend(true, {}, data);
                $scope.model.companyOrig = jQuery.extend(true, {}, $scope.model.company);

                if ($scope.model.companyOrig && $scope.model.companyOrig.Address)
                    $scope.model.companyOrig.Address = jQuery.extend(true, {}, $scope.model.company.Address);
                if ($scope.model.company.Address.CityId == null)
                    $scope.model.companyOrig.Address.CityId = null;

                this.getBranches($scope.model.company.Id);
            }
            else {
                $scope.formName = "დამატება";
                $scope.model.companyOrig = <Interface.ICompany>{
                    Address: null,
                    VatType: <any>"0"
                };
            }

            $timeout(() => {
                $("#rs_code").focus();
                var tab_contents = $(".tab-content");
                for (var i = 0; i < tab_contents.length; i++)
                    $((tab_contents[i])).css("background-color", "#ffffff");
            });
        }

        initialisation = () => {
            this.$scope.isSaved = false;
            this.$scope.model = {
                vatTypes: [
                    {
                        Id: 0,
                        Name: "დღგ-ის გადამხდელი"
                    },
                    {
                        Id: 1,
                        Name: "იბეგრება დღგ-ის ნულოვანი განაკვეთით"
                    },
                    {
                        Id: 2,
                        Name: "დღგ-ის არგადამხდელი"
                    }
                ],
                branch: {
                    maxId: 0,
                    firstMaxId: 0,
                    forSaveBranches: new Array<Apex.Crm.Interface.ICompanyBranch>(),
                    forDeleteBranches: new Array<Apex.Crm.Interface.ICompanyBranch>()
                }
            }


            this.$scope.settings = {
                tabIsEditable: false,
                saveTabChanges: {},
                disablePopUpBtn: true
            }
            this.$scope.Components = {
                branchTreeList: this.initBranchTreeList()
            }


            this.$scope.events = {
                closeForm: this.closeForm,
                save: this.save,
                tabChanged: this.tabChanged,
                branchEvents: {
                    refresh: () => {
                        if (!!this.$scope.model.company && this.$scope.model.company.Id > 0)
                            this.getBranches(this.$scope.model.company.Id);
                    },
                    expand: this.expandBranch,
                    collapse: this.collapseBranch,
                    editBranch: this.editBranch,
                    addBranch: this.addBranch,
                    addBranchUnder: this.addBranchUnder,
                    delete: this.deleteBranch,
                },
                nameTextBoxChange: this.nameTextBoxChange,
                searchBtnClick: this.searchBtnClick,

            }
        }
        /******************************** Constructors and Initialization ********************************* */

        /** Services Methods */
        getCompany = (rsCode: string) => {
            if (rsCode != undefined && rsCode.trim() != "") {
                this.crmService.getCompanies(null, null, null, null, rsCode).then(result => {
                    if (result.Result.length > 0) {
                        this.$scope.model.company = result.Result[0];
                        this.getBranches(this.$scope.model.company.Id);
                    } else {
                        this.crmService.getRSCompanyInfo(rsCode).then(resp => {
                            if (resp != undefined && resp.Result != undefined && resp.Result.Name != undefined) {
                                this.$scope.model.company.Name = resp.Result.Name;
                                this.$scope.model.company.VatType = resp.Result.IsVatpayer == false ? 2 : 0;
                                $("#compName").focus();
                                this.changeParentBranch();
                            }
                        });
                    }
                });
            }
        }

        getBranches = (company_id: number) => {
            kendo.ui.progress($("#branchArea"), true);
            this.crmService.getCompanyBranch(null, company_id).then(result => {
                this.$scope.settings.tabIsEditable = true;
                if (result.Result != undefined && result.Result != null) {
                     for (var index = 0; index < result.Result.length; index++) {
                        if(!result.Result[index].ParentId){
                            result.Result[index].ParentId=null;
                            break;
                        }
                    }
                    var max = _.max(<Array<Apex.Crm.Interface.ICompanyBranch>>result.Result, (item) => { return item.Id });
                    if (max != undefined && max != null) {
                        this.$scope.model.branch.firstMaxId = max.Id;
                        this.$scope.model.branch.maxId = max.Id;
                    }


                }

                // this.$scope.model.branch.selectedBranch = null;
                this.$scope.model.branch.dataSource = new kendo.data.TreeListDataSource({
                    data: result.Result,
                    schema: {
                        model: {
                            expanded: true,
                            id: "Id",
                            fields: {
                                parentId: { field: "ParentId", nullable: true }
                            },
                        }
                    }
                });
                this.$scope.Components.branchTreeList.treeList.setDataSource(this.$scope.model.branch.dataSource);
                kendo.ui.progress($("#branchArea"), false);

            });
        }

        /******************************Service Methods ***************************** */

        /** Methods */
        initBranchAddress = (branch) => {
            if (branch == undefined)
                return;
            if (!branch.Address) {
                branch.Address = {
                    Location: {
                    }
                };
            } else
                if (!branch.Address.Location) {
                    branch.Address.Location = {
                    };
                }
        }
        initBranchTreeList = () => {
            var _treeList: Apex.Components.IApxTreeList = {
                options: {
                    columns: this.BranchTreeListColumns,
                    height: 435,
                    dataSource: {
                        data: this.$scope.model.branch.dataSource,
                        schema: {
                            model: {
                                expanded: true,
                                id: "Id",
                                parentId: "ParentId",
                                fields: {
                                    Id: { type: "number", nullable: false },
                                    ParentId: { nullable: true, type: "number" }
                                },
                            },
                        }
                    },
                    change: this.branchTreeListSelectedChange,
                    editable: { mode: "inline" },
                    filterable: true,
                    resizable: true,
                    sortable: { mode: "single" },
                    selectable: true,
                }
            }
            return _treeList;
        }
        changeParentBranch = (): angular.IPromise<any> => {
            var _dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();
            if (_dataSource.length > 0) {
                for (var item = 0; item < _dataSource.length; item++) {
                    if (_dataSource[item].parentId == null) {
                        _dataSource[item].Name = this.$scope.model.company.Name + "(სათაო)";
                        this.changeForSaveList(_dataSource[item]);
                        this.$scope.Components.branchTreeList.treeList.dataSource.sync();
                        return;
                    }
                }
            } else {
                var id = this.$scope.model.branch.maxId;
                _dataSource.push(<Apex.Crm.Interface.ICompanyBranch>{
                    Id: id,
                    ParentId: null,
                    Name: this.$scope.model.company.Name + "(სათაო)"
                });
                this.$scope.model.branch.forSaveBranches.push(<Apex.Crm.Interface.ICompanyBranch><Apex.Crm.Interface.ICompanyBranch>{
                    Id: id,
                    ParentId: null,
                    Name: this.$scope.model.company.Name + "(სათაო)"
                })
                this.$scope.Components.branchTreeList.treeList.dataSource.sync();
            }
            return;
        }
        changeForSaveList = (data) => {
            if (_.some(this.$scope.model.branch.forSaveBranches, (item) => { return data.id == item.Id })) {
                this.$scope.model.branch.forSaveBranches = _.filter(this.$scope.model.branch.forSaveBranches, (item) => {
                    return item.Id != data.id;
                });
            }
            this.$scope.model.branch.forSaveBranches.push(<Apex.Crm.Interface.ICompanyBranch>data);
        }
        openModal = (data) => {

            var dlg = Core.Utils.ModalForm.openModalForm('companyBranch.modal.html', BranchModal.getName(), data, Core.Utils.ModalSize.Medium);
            dlg.result.then(result => {

                if (typeof result !== "undefined" && result != null && result != false) {
                    var _dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();
                    if (data.branch == null) {

                        if (result.Id == 0 && result.ParentId != null)
                            result.Id = ++this.$scope.model.branch.maxId;
                        if (data.parentId != undefined && data.parentId != null)
                            result.parentId = result.ParentId = data.parentId != null ? data.parentId : data.branch.parentId;
                    }
                    result.Address = this.emptyAddressToNull(result.Address);

                    if (_dataSource.length > 0) {
                        for (var item = 0; item < _dataSource.length; item++) {
                            if (Number(item) !== Number(item))
                                break;
                            if (_dataSource[item].id === result.id) {
                                _dataSource[item] = result;
                                this.changeForSaveList(result);
                                this.$scope.Components.branchTreeList.treeList.dataSource.data(_dataSource);
                                return;
                            }
                        }
                    }
                    this.$scope.model.branch.forSaveBranches.push(result);
                    result.parentId = result.ParentId;
                    _dataSource.push(result);
                    this.$scope.Components.branchTreeList.treeList.dataSource.data(_dataSource);
                }
            });
        }
        tabEditable = () => {
            return this.$scope.model.branch.selectedBranch != undefined && this.$scope.model.branch.selectedBranch != null
                && this.$scope.model.branch.selectedBranch.Id > 0 && this.$scope.model.branch.selectedBranch.Id;
        }
        /*************************** Methods ****************************** */

        /** events */
        nameTextBoxChange = () => {
            this.changeParentBranch();
        }
        editBranch = () => {
            if (this.$scope.settings.disablePopUpBtn) return;

            if (!this.$scope.model.branch.selectedBranch)
                return;
            this.initBranchAddress(this.$scope.model.branch.selectedBranch);
            this.openModal({
                parentId: null,
                branch: this.$scope.model.branch.selectedBranch
            });
        }
        addBranch = () => {
            if (this.$scope.settings.disablePopUpBtn) return;

            if (this.$scope.model.branch.selectedBranch != null && this.$scope.model.branch.selectedBranch != undefined) {
                this.openModal({
                    parentId: this.$scope.model.branch.selectedBranch.parentId == null ? this.$scope.model.branch.selectedBranch.Id : this.$scope.model.branch.selectedBranch.parentId,
                    branch: null
                });
            }
        }
        addBranchUnder = () => {
            if (this.$scope.settings.disablePopUpBtn) return;

            var branch = jQuery.extend(true, {}, this.$scope.model.branch.selectedBranch);
            if (branch != null && branch != undefined) {
                this.openModal({
                    parentId: branch.id,
                    branch: null
                });
            }
        }
        expandBranch = () => {
            var rows = this.$scope.Components.branchTreeList.treeList.element.find("tr.k-treelist-group");
            for (var i = 0; i < rows.length; i++) {
                this.$scope.Components.branchTreeList.treeList.expand(rows[i]);
            }
        }
        collapseBranch = () => {
            var elem_id = this.$scope.Components.branchTreeList.treeList.element.attr("id");
            var elem = $("#" + elem_id + " tbody>tr:eq(0)");
            if (elem.length > 0)
                (<any>$("#" + elem_id).data("kendoTreeList")).collapse(elem);
        }
        deleteBranch = () => {
            if (this.$scope.settings.disablePopUpBtn) return;

            var selected_branch = this.$scope.Components.branchTreeList.treeList.select();
            if (selected_branch.length == 0)
                return;
            Core.Utils.ModalForm.openDeleteConfirmations(() => {

                var dataSource = this.$scope.Components.branchTreeList.treeList.dataSource.data();
                if (this.$scope.model.branch.selectedBranch != undefined && this.$scope.model.branch.selectedBranch != null) {
                    var for_delete = this.getAllChildren(dataSource, [this.$scope.model.branch.selectedBranch.Id]);
                    for_delete.push(this.$scope.model.branch.selectedBranch);
                    this.$scope.Components.branchTreeList.treeList.removeRow(selected_branch);
                    for (var i = 0; i < for_delete.length; i++) {
                        if (_.some(this.$scope.model.branch.forSaveBranches, (item) => { return item.Id == for_delete[i].Id })) {
                            this.$scope.model.branch.forSaveBranches = _.filter(this.$scope.model.branch.forSaveBranches, (item) => {
                                return item.Id != for_delete[i].Id;
                            });
                            if (for_delete[i].Id <= this.$scope.model.branch.firstMaxId) {
                                this.$scope.model.branch.forDeleteBranches.push(<Apex.Crm.Interface.ICompanyBranch>for_delete[i]);
                            }
                        }
                        else {
                            this.$scope.model.branch.forDeleteBranches.push(<Apex.Crm.Interface.ICompanyBranch>for_delete[i]);
                        }
                    }
                }
            });
        }
        getAllChildren = (dataSource, parents): Array<any> => {
            var _returnData: any = [];
            for (var i = 0; i < parents.length; i++) {
                for (var j = 0; j < dataSource.length; j++) {
                    if (dataSource[j].parentId == parents[i]) {
                        _returnData.push(dataSource[j]);
                        var _retrive = this.getAllChildren(dataSource, [dataSource[j].Id]);
                        if (_retrive != undefined && _retrive != null && _retrive.length > 0) {
                            for (var t = 0; t < _retrive.length; t++)
                                _returnData.push(_retrive[t]);
                        }
                    }
                }
            }
            return _returnData;
        }
        branchTreeListSelectedChange = (args: any) => {

            var dataItem = (<JQuery>args.sender.select()).data().$scope.dataItem;
            Apex.Core.Utils.Helper.safeApply(this.$scope, (scope) => {
                if (this.$scope.model.branch.selectedBranch == dataItem)
                    return;
                this.$scope.model.branch.selectedBranch = dataItem;
                if (this.$scope.model.branch.selectedBranch.Id > this.$scope.model.branch.firstMaxId)
                    this.$scope.model.branch.selectedBranch.Id = -1;
            });

        }


        convertToTreeListDataSource = (data: any): kendo.data.TreeListDataSource => {
            return new kendo.data.TreeListDataSource({
                data: data,
                schema: {
                    model: {
                        expanded: true,
                        id: "Id",
                        parentId: "ParentId",
                        fields: {
                            parentId: { field: "ParentId", nullable: true }
                        },
                    }
                }
            });
        }

        tabChanged = (index: number) => {
            if (index === 0)
                $(".slider4").css("transform", "translate3d(0px, 0px, 0px)");
            else if (index === 1)
                $(".slider4").css("transform", "translate3d(-34%, 0px, 0px)");
        }
        closeForm = () => {

            if (!this.$scope.model.company.AddressId)
                this.$scope.model.company.Address = this.emptyAddressToNull(this.$scope.model.company.Address);

            if (JSON.stringify(this.$scope.model.company) != JSON.stringify(this.$scope.model.companyOrig)
                || this.$scope.model.branch.forSaveBranches.length > 0 || this.$scope.model.branch.forDeleteBranches.length > 0) {
                Core.Utils.ModalForm.openConfirmation("გსურთ შევინახოთ ცვლილებები?", false).result.then(() => {
                    this.save(false);
                    this.$uibModalInstance.close(true);
                }, () => this.$uibModalInstance.close(this.$scope.isSaved));
            } else this.$uibModalInstance.close(this.$scope.isSaved);
        }



        saveBranchFromList = (refresh_data: boolean, companyId: number) => {
            this.changeCompanyIdForSaveBranches(companyId);
            if (this.$scope.model.branch.forSaveBranches.length > 0) {
                this.$scope.isSaved = true;
                this.crmService.saveBranches(this.$scope.model.branch.forSaveBranches).then(result => {
                    if (refresh_data)
                        this.getBranches(this.$scope.model.company.Id);
                    this.toaster.pop('success', this.$translate.instant('Info'), "ფილიალებსი შენახვა წარმატებით დასრულდა");
                    this.$scope.model.branch.forSaveBranches = [];
                });
            }
        }
        /////??????????? გასასწორებელია        
        changeCompanyIdForSaveBranches = (companyId: number) => {
            var id_indexer: number = -1;
            for (var i = 0; i < this.$scope.model.branch.forSaveBranches.length; i++) {
                this.$scope.model.branch.forSaveBranches[i].CompanyId = companyId;
                if (this.$scope.model.branch.forSaveBranches[i].Id > this.$scope.model.branch.firstMaxId) {
                    var old_Id = this.$scope.model.branch.forSaveBranches[i].Id;

                    this.$scope.model.branch.forSaveBranches[i].Id = id_indexer--;
                    for (var j = i; j < this.$scope.model.branch.forSaveBranches.length; j++) {
                        if (this.$scope.model.branch.forSaveBranches[j].ParentId == old_Id) {
                            (<any>this.$scope.model.branch.forSaveBranches[j]).parentId = this.$scope.model.branch.forSaveBranches[j].ParentId = this.$scope.model.branch.forSaveBranches[i].Id;
                        }
                    }
                }

            }
        }

        deleteBranchFromList = () => {
            if (this.$scope.model.branch.forDeleteBranches.length > 0) {
                this.$scope.isSaved = true;
                var errors = [];
                for (var i = 0; i < this.$scope.model.branch.forDeleteBranches.length; i++) {
                    this.crmService.deleteBranch(this.$scope.model.branch.forDeleteBranches[i].Id).then(result => {
                    });
                }
                if (errors.length > 0) {
                    this.toaster.pop('error', "Error", this.$translate.instant('dontDelete'));
                } else {
                    this.$scope.model.branch.forDeleteBranches = [];
                }
            }
        }

        emptyAddressToNull = (address: Apex.Crm.Interface.IAddress): Interface.IAddress => {
            if (address.BlockId || address.CityId || address.FullAddress
                || address.Location || address.PostalCode)
                return address;
            else
                return null;

        }

        save = (refresh_data: boolean = true) => {
            this.$scope.settings.saveTabChanges.save();
            if (JSON.stringify(this.$scope.model.company) != JSON.stringify(this.$scope.model.companyOrig)) {


                this.crmService.saveCompany(this.$scope.model.company).then(result => {
                    this.$scope.isSaved = true;
                    if (result.Result > 0) {
                        this.$scope.model.company.Id = result.Result;
                        this.$scope.model.companyOrig = jQuery.extend(true, {}, this.$scope.model.company);
                        this.toaster.pop('success', this.$translate.instant('Info'), "კომპანიის შენახვა წარმატებით დასრულდა");
                        this.saveBranchFromList(refresh_data, this.$scope.model.company.Id);
                        this.deleteBranchFromList();
                    }
                });
            } else {
                this.saveBranchFromList(refresh_data, this.$scope.model.company.Id);
                this.deleteBranchFromList();
            }
        }
        searchBtnClick = () => {
            if (this.$scope.model.company.Code != undefined && this.$scope.model.company.Code.trim() != "")
                this.getCompany(this.$scope.model.company.Code);
        }
        /// ************ Events ***********************/



        BranchTreeListColumns: Apex.Core.Interfaces.ITreeListColumn[] = [
            { field: "Id", hidden: true },
            { field: "Code", width: 150, titleTranslated: "code" },
            { field: "Name", width: 200, titleTranslated: "name" },
            { field: "Address.FullAddress", width: 200, titleTranslated: "address" }
        ];
    }
}
