/// <reference path='./../_reference.ts' />
module Apex.Crm.Directives {
    "use strict";
    export class ApxCrmTab implements angular.IDirective {
        restrict = "E";
        templateUrl = 'directives\apx.crm.tab.html';
        scope = {
            selectedBranch: '=',
            selectedCompany: '=',
            isEditable: '=',
            saveChanges: '='
        };
        controller = Apex.Crm.Controllers.CrmTabController.getName();
        link = (scope, element, attrs, controller) => {

            scope.internalControl =scope.saveChanges || {};
            scope.internalControl.save=controller.saveChanges;
            

            scope.$watch('selectedCompany', (value) => {
                controller.companySelectedChanged(value);
            });
            scope.$watch('selectedBranch', (value) => {
                controller.branchSelectedChanged(value);
                if (scope.isEditable == undefined)
                    controller.editableChanged(value > 0);

            });
            scope.$watch('isEditable', (value) => {
                controller.editableChanged(value, true);
            });

        }
        constructor(private $translate) {
        }

        public static factory() {
            var directive = ($translate) => {
                return new ApxCrmTab($translate);
            };
            directive['$inject'] = ['$translate'];
            return directive;
        }
    }
}