/// <reference path='../../_reference.ts' />
module Apex.Crm.Controllers {
    export interface BankAccountEditScope extends angular.IScope {
        formName: string;
        repositoriesDates:{
            bankes?:Array<Apex.Crm.Interface.IBank>
        },
        events:{
            closeForm:Function,
            save:Function
        },
        model:{
            bankAccount?:Apex.Crm.Interface.ICompanyBankAccount,
        }
    }

    export class BankAccountModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'crmService', Globals.modalInstance, Globals.toaster,'$timeout'];
        constructor(private $scope: BankAccountEditScope, private $translate, data:any, private crmService: Apex.Crm.Services.Crm,
            private $uibModalInstance, private toaster, private $timeout) {
            super(arguments, PersonalModal.$inject);

            this.initialisation();
            this.initRepositories();

            if (data.BankAccount != undefined && data.BankAccount != null) {
                $scope.formName = "რედაქტირება";
                this.$scope.model.bankAccount=data.BankAccount;
            }
            else {
                $scope.formName = "დამატება";
                this.$scope.model.bankAccount={
                    Account:null,
                    BankId:null,
                    Id:null,
                    IsDefault:false,
                    Note:null,
                    BranchId:data.BranchId
                }
                this.$scope.model.bankAccount.BranchId=data.branchId;
            }

            $timeout(()=>{
                $("#bank").focus();
            })

        }

        initRepositories=()=>{
            this.crmService.getCompanyBankAccounts(null).then((result)=>{
                this.$scope.repositoriesDates.bankes=result.Result;
            });
        }

        initialisation = () => {      
            this.$scope.repositoriesDates={
                bankes:new Array<Apex.Crm.Interface.ICompanyBankAccountView>()
            }
            this.$scope.model={};

            this.$scope.events={
                closeForm:()=>{
                    this.$uibModalInstance.close(false);
                },
                save:this.saveBankAccount
            };

        }
        saveBankAccount=()=>{
            this.crmService.saveBankAccount(this.$scope.model.bankAccount).then(result=>{
                this.$uibModalInstance.close(true);
            });
          
        }        
    }
}
